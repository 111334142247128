import { log, SHOW_LOGS } from "utilities/logging"

/**
 * The type of event to track in Tealium.
 * This is either a page view or a link click.
 */
type TealiumEvent = "view" | "link"

interface TealiumData {
  type: TealiumEvent
  page?: string
  email_address?: string
  customer_id?: string
  uid?: string
  onSuccess?: () => void
}

/**
 * Sends a tracking event to Tealium
 *
 * @param eventName - Name of the event to track
 * @param data - TealiumData object containing event type ('view'|'link') and optional tracking properties
 * @throws If Tealium event fails to send
 */
export const sendTealiumEvent = async (
  eventName: string,
  data: TealiumData,
): Promise<void> => {
  const { type, onSuccess, ...eventData } = data
  try {
    await new Promise<void>(resolve => {
      ;(window as any).utag?.[type](
        { tealium_event: eventName, ...eventData } as any,
        () => {
          log(SHOW_LOGS.TEALIUM, `✅ Tealium event: ${eventName}`, eventData)
          resolve()
        },
      )
    })
    onSuccess?.()
  } catch (error) {
    log(SHOW_LOGS.TEALIUM, `❌ Tealium event: ${eventName}`, error)
    throw error
  }
}
