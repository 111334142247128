/**
 * Creates a condition with AND logic
 * @param input - Field name or array of field names that must all be true
 * @param value - Value to set for the condition(s)
 * @returns Conditional object with AND logic
 */
export const when = (input: string | string[], value: string) => {
  // Convert input to array if it's a single string
  const fieldNames = Array.isArray(input) ? input : [input]

  // Create condition objects for each field name
  const conditions = fieldNames.map(fieldName => ({
    name: fieldName,
    value,
  }))

  return { conditions }
}

/**
 * Creates a condition with OR logic
 * @param fieldNames - Array of field names where any can be true
 * @param value - Value to set for the conditions
 * @returns Conditional object with OR logic
 */
export const whenAny = (fieldNames: string[], value: string) => {
  // Create condition objects for each field name
  const conditions = fieldNames.map(fieldName => ({
    name: fieldName,
    value,
  }))

  return {
    conditions,
    logicalOr: true,
  }
}

export const conditionals = {
  // Standardized Test Fields
  showStandardizedTestFields: when("test_scores_considered", "Yes"),
  showSatFields: when(["test_scores_considered", "sat_considered"], "Yes"),
  showSatFutureFields: when(
    ["test_scores_considered", "sat_considered", "sat_future_tests"],
    "Yes",
  ),
  showActFields: when(["test_scores_considered", "act_considered"], "Yes"),
  showActFutureFields: when(
    ["test_scores_considered", "act_considered", "act_future_tests"],
    "Yes",
  ),

  // English Proficiency Fields
  showEnglishProficiencyFields: when(
    "english_language_proficiency_test",
    "Yes",
  ),
  showToeflFields: when(
    ["english_language_proficiency_test", "toefl_ielts_considered"],
    "Yes",
  ),
  showDuolingoFields: when(
    ["english_language_proficiency_test", "duolingo_considered"],
    "Yes",
  ),
  showIeltsFields: when(
    ["english_language_proficiency_test", "ielts_considered"],
    "Yes",
  ),
  showPteFields: when(
    ["english_language_proficiency_test", "pte_considered"],
    "Yes",
  ),
}
