import { ApplicationData } from "types"
import { CREIGHTON_MAJORS } from "./creighton_options"
import { conditionals, optionize } from "data/utils"

export const CREIGHTON_DATA: ApplicationData = {
  layout: {
    version: "1",
  },
  meta: {
    title: "Creighton University",
    contact: {
      email: "admissions@creighton.edu",
      phone: "402-280-2703",
      website: "https://www.creighton.edu/generalcounsel/web-privacy-policy",
    },
    logo: "https://www.creighton.edu/themes/creighton/images/Creighton-University-Logo-white.svg",
    footerLogo:
      "https://www.creighton.edu/themes/creighton/images/Creighton-University-Logo-white.svg",
    favicon: "https://www.creighton.edu/sites/default/files/icon.png",
    name: "Creighton University",
    submissionRedirectUrl: "https://www.creightonu.org/application/submitted",
  },
  susiPages: {
    signIn: {
      heading: "Sign In",
      htmlContent:
        '<p>Enter your email address and password below to continue filling out your Siena Application. Need to start a brand new application? <a class="userflow">Sign up now.</a></p>',
    },
    signUp: {
      heading: "Here's your Siena Express Application",
      subheading:
        "This application is free and takes less than 10 minutes to complete.",
      htmlContent:
        '<section><p><strong>Plus, Siena guarantees:</strong></p><ul><li>At least $57,000 in scholarships toward your degree</li><li>Graduate in 4 years, or Siena pays for the rest</li><li>Personalized career experience from the #1 college in NY for getting a job</li></ul><p>Enter your email address and create a password to begin filling out your Siena Express Application, or <a class="userflow">continue an application you started</a>.</p></section>',
    },
    forgotPassword: {
      heading: "Passphrase Reset",
    },
  },
  pages: [
    {
      id: "about-you",
      slug: "about-you",
      title: "About You",
      navTitle: "About You",
      description: "We'd like to get to know you better.",
      sections: [
        {
          title: "Personal Information",
          description: "",
          fields: [
            {
              name: "first_name",
              type: "text",
              label: "First/Given Name",
              isRequired: true,
              group: 1,
              order: 1,
            },
            {
              name: "middle_name",
              type: "text",
              label: "Middle Name",
              group: 1,
              order: 2,
            },
            {
              name: "last_name",
              type: "text",
              label: "Last/Family/Surname",
              isRequired: true,
              group: 1,
              order: 3,
            },
            {
              name: "suffix",
              type: "text",
              label: "Suffix",
            },
            {
              name: "preferred_name",
              type: "text",
              label: "Preferred First Name",
              helperText:
                "Would you like to share another first name that you go by? We may use it in future communications.",
            },
            {
              name: "date_of_birth",
              type: "date",
              label: "Date of Birth",
              isRequired: true,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  isInPast: true,
                },
              },
            },
            {
              name: "dob_checker",
              type: "redirectModal",
              label: null,
              heading: "Are you looking for Adult Undergraduate Application?",
              description:
                "Based on the date of birth you provided, you might be a candidate for our Adult Undergraduate program. If this is correct, please use the Adult Undergraduate Application.",
              redirectButton: {
                text: "Go to Undergraduate Application",
                redirectUrl:
                  "https://choose.creighton.edu/apply/?sr=d77757ef-908f-4c9d-8d74-8d9111805b76",
              },
              cancelButton: {
                text: "Correct Date",
              },
              show: {
                conditions: [
                  {
                    name: "date_of_birth",
                    date: {
                      // Show if a user will be 23 years old by August 1st of the current year
                      before: `${new Date().getFullYear() - 23}-08-01`,
                    },
                  },
                ],
              },
            },
            {
              name: "sex",
              type: "radio",
              label: "What is your legal sex?",
              options: optionize(["Male", "Female"]),
              isRequired: true,
            },
            {
              name: "gender_identity",
              type: "radio",
              label: "What is your gender identity?",
              options: optionize([
                "Female",
                "Male",
                "Nonbinary",
                ["Add Another Gender", "Other"],
              ]),
            },
            {
              name: "gender_identity_other",
              type: "text",
              label: "Your gender identity",
              show: {
                conditions: [
                  {
                    name: "gender_identity",
                    value: "Other",
                  },
                ],
              },
            },
            {
              name: "preferred_pronouns",
              type: "radio",
              label: "Pronouns",
              options: optionize([
                "He / Him / His",
                "She / Her / Hers",
                "They / Them / Theirs",
                "Other",
              ]),
            },
            {
              name: "preferred_pronouns_other",
              type: "text",
              label: "Your Preferred Pronouns",
              show: {
                conditions: [
                  {
                    name: "preferred_pronouns",
                    value: "Other",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Your Background",
          fields: [
            {
              name: "religious_preference",
              label: "Religious Preference",
              helperText:
                "Start typing to find and select your religious preference.",
              type: "religionSearch",
              isRequired: false,
            },
            {
              name: "religious_preference_other",
              type: "text",
              label: "Religious Preference",
              show: {
                conditions: [
                  {
                    name: "religious_preference",
                    value: "Other",
                  },
                  {
                    name: "religious_preference",
                    value: "Not Found",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "hispanic",
              label: "Are you Hispanic or Latino/a/x?",
              helperText:
                "Colleges and universities are asked by many groups, including the federal government, accrediting associations, college guides, and newspapers, to describe the ethnic/racial backgrounds of their students and employees. In order to respond to these requests, we ask you to answer the following two questions.",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "race",
              label: "Your Race",
              helperText:
                "Regardless of your answer to the prior question, please check one or more of the following groups in which you consider yourself to be a member:",
              type: "checkbox",
              options: optionize([
                "American Indian or Alaska Native",
                "Asian",
                "Black or African American",
                "Native Hawaiian or Other Pacific Islander",
                "White",
                "Prefer not to say",
              ]),
            },
          ],
        },
        {
          title: "Citizenship",
          description: "",
          fields: [
            {
              name: "citizenship",
              label: "Your Citizenship",
              helperText:
                "U.S. citizenship is not a requirement for admission. Your response may prompt additional questions you'll need to answer.",
              type: "radio",
              options: optionize([
                "U.S. Citizen or U.S. National",
                "U.S. Dual Citizen",
                "U.S. Permanent Resident",
                "Not a U.S. Citizen",
              ]),
              isRequired: true,
            },
            {
              name: "citizenship_country",
              label: "In which other country are you a citizen?",
              helperText: "Start typing to find and select a country.",
              type: "countrySearch",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "primary_language",
              label: "What language is spoken at home?",
              type: "languageSearch",
            },
            {
              name: "primary_language_other",
              type: "text",
              label: "Your language",
              show: {
                conditions: [
                  {
                    name: "primary_language",
                    value: "Other",
                  },
                  {
                    name: "primary_language",
                    value: "Not Found",
                  },
                ],
                logicalOr: true,
              },
            },
          ],
        },
        {
          title: "Your Contact Information",
          description: "Let us know how best to reach you and where you live.",
          fields: [
            {
              name: "email_address",
              label: "Email Address",
              type: "email",
              helperText:
                "This is the email address you provided when you started your application so it can't be changed.",
            },
            {
              name: "phone_number",
              label: "Preferred Phone",
              type: "tel",
              helperText:
                "We will send you application status and other important updates via text. You can opt-out at any time.",
            },
            {
              name: "phone_text_opt-in",
              label: null,
              type: "checkbox",
              options: [
                {
                  label:
                    "Yes, I'd like to receive application status and other important updates via text from Creighton University. (Standard message charges apply.)",
                  value: "Yes",
                },
              ],
            },
          ],
        },
        {
          title: "Permanent Address",
          fields: [
            {
              name: "country",
              label: "Country",
              type: "countrySearch",
              isRequired: true,
              // prefill: [
              //   {
              //     value: "United States",
              //     conditionSet: {
              //       conditions: [
              //         {
              //           name: "citizenship",
              //           value: "U.S. Dual Citizen",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Permanent Resident",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Citizen or U.S. National",
              //         },
              //       ],
              //       logicalOr: true,
              //     },
              //   },
              // ],
            },
            {
              name: "address1",
              label: "Address",
              type: "text",
              isRequired: true,
              group: 4,
              order: 1,
              width: "60%",
            },
            {
              name: "address2",
              label: "Apartment, suite, etc.",
              type: "text",
              group: 4,
              order: 2,
              width: "40%",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "city",
              label: "City",
              type: "text",
              group: 2,
              order: 1,
              isRequired: true,
            },
            {
              name: "state",
              label: "State",
              type: "stateSearch",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "zip",
              label: "Zip Code",
              type: "zip_code",
              group: 2,
              order: 3,
              validations: {
                length: {
                  is: 5,
                },
              },
              show: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "state_province",
              label: "State/Province",
              type: "text",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "postal_code",
              label: "Postal Code",
              type: "text",
              group: 2,
              order: 3,
              validations: {
                length: {
                  min: 5,
                  max: 15,
                },
              },
              show: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "permanent_address",
              label:
                "Is your mailing address the same as your permanent address?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Mailing Address",
          show: {
            conditions: [
              {
                name: "permanent_address",
                value: "No",
              },
            ],
          },
          fields: [
            {
              name: "mailing_country",
              label: "Country",
              type: "countrySearch",
              show: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "mailing_address1",
              label: "Address",
              type: "text",
              show: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                ],
              },
              group: 7,
              order: 1,
              width: "60%",
            },
            {
              name: "mailing_address2",
              label: "Apartment, suite, etc.",
              type: "text",
              group: 7,
              order: 2,
              width: "40%",
              show: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "mailing_city",
              label: "City",
              type: "text",
              group: 6,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "mailing_state",
              label: "State",
              type: "stateSearch",
              group: 6,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                  {
                    name: "mailing_country",
                    value: "",
                  },
                  {
                    name: "mailing_country",
                    value: "United States",
                  },
                ],
                logic: "0&&(1||2)",
              },
              isRequired: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                  {
                    name: "mailing_country",
                    value: "",
                  },
                  {
                    name: "mailing_country",
                    value: "United States",
                  },
                ],
                logic: "0&&(1||2)",
              },
            },
            {
              name: "mailing_zip",
              label: "Zip Code",
              type: "zip_code",
              group: 6,
              order: 3,
              validations: {
                length: {
                  is: 5,
                },
              },
              show: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                  {
                    name: "mailing_country",
                    value: "",
                  },
                  {
                    name: "mailing_country",
                    value: "United States",
                  },
                ],
                logic: "0&&(1||2)",
              },
              isRequired: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                  {
                    name: "mailing_country",
                    value: "",
                  },
                  {
                    name: "mailing_country",
                    value: "United States",
                  },
                ],
                logic: "0&&(1||2)",
              },
            },
            {
              name: "mailing_state_province",
              label: "State/Province",
              type: "text",
              group: 6,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                  {
                    name: "mailing_country",
                    notValue: "United States",
                  },
                  {
                    name: "mailing_country",
                    notValue: "",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                  {
                    name: "mailing_country",
                    notValue: "United States",
                  },
                  {
                    name: "mailing_country",
                    notValue: "",
                  },
                ],
              },
            },
            {
              name: "mailing_postal_code",
              label: "Postal Code",
              type: "text",
              group: 6,
              order: 3,
              validations: {
                length: {
                  min: 5,
                  max: 15,
                },
              },
              show: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                  {
                    name: "mailing_country",
                    notValue: "United States",
                  },
                  {
                    name: "mailing_country",
                    notValue: "",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                  {
                    name: "mailing_country",
                    notValue: "United States",
                  },
                  {
                    name: "mailing_country",
                    notValue: "",
                  },
                ],
              },
            },
            {
              name: "mailing_address_affective_until",
              label: "Address Effective Until",
              type: "month",
              show: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "permanent_address",
                    value: "No",
                  },
                ],
              },
              validations: {
                date: {
                  maxYearsInFuture: 100,
                },
              },
            },
          ],
        },
      ],
    },
    {
      id: "student-family",
      slug: "student-family",
      title: "Your Family",
      navTitle: "Family",
      description: "Help us get to know your family",
      sections: [
        {
          title: "Parent/Guardian",
          description: "Tell us about your parents and/or guardians",
          nestedSectionsMin: 1,
          nestedSectionsMax: 4,
          nestedSectionTitle: "Parent/Guardian {{x}}",
          nestedSectionItemName: "Parent/Guardian",
          controlSectionName: "parents",
          fields: [
            {
              name: "parent_existence",
              label: "Do you have parent and/or guardian?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              prefill: [
                {
                  value: "Yes",
                },
              ],
              hide: true,
            },
            {
              name: "parent_type",
              label: "Relationship",
              type: "radio",
              options: optionize([
                "Mother",
                "Father",
                "Legal Guardian",
                "Other",
              ]),
              isRequired: true,
            },
            {
              name: "parent_type_other",
              type: "text",
              label: "Relationship",
              show: {
                conditions: [
                  {
                    name: "parent_type",
                    value: "Other",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_type",
                    value: "Other",
                  },
                ],
              },
            },
            {
              name: "parent_living",
              label: "Is parent/guardian living?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "parent_first_name",
              label: "First Name",
              type: "text",
              group: 1,
              order: 1,
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_last_name",
              label: "Last Name",
              type: "text",
              group: 1,
              order: 2,
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_communication_consent",
              label: "May we communicate with this parent/guardian?",
              type: "radio",
              options: optionize([
                ["Yes, you can communicate with my parent", "Yes"],
                ["No, you cannot communicate with my parent", "No"],
              ]),
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_cell_phone",
              label: "Mobile Phone",
              type: "tel",
              hide: {
                conditions: [
                  {
                    name: "parent_communication_consent",
                    value: "No",
                  },
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "parent_email_address",
              label: "Email Address",
              helperText:
                "We'd like to send your parent important updates about your application and about financial aid.",
              type: "email",
              hide: {
                conditions: [
                  {
                    name: "parent_communication_consent",
                    value: "No",
                  },
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "parent_occupation",
              label: "Occupation/Employer",
              type: "text",
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_education_level",
              label: "Education Level",
              type: "select",
              options: optionize([
                "Some grade/primary school",
                "Completed grade/primary school",
                "Some high/secondary school",
                "Graduated from high/secondary school (or equivalent)",
                "Some trade school or community college",
                "Some college/university",
                "Graduated from college/university",
                "Graduate School",
                "Doctoral Program",
              ]),
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_alumni",
              label: "Did they attend Creighton University?",
              type: "radio",
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_education_level",
                    value: "Some college/university",
                  },
                  {
                    name: "parent_education_level",
                    value: "Graduated from college/university",
                  },
                  {
                    name: "parent_education_level",
                    value: "Graduate School",
                  },
                  {
                    name: "parent_education_level",
                    value: "Doctoral Program",
                  },
                ],
                logic: "0&&(1||2||3||4)",
              },
              options: optionize(["Yes", "No"]),
              validations: {
                length: {
                  min: 5,
                  max: 15,
                },
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_country",
                    value: "US",
                  },
                ],
              },
            },
            {
              name: "parent_grad_year",
              label: "Graduation Year",
              type: "year",
              validations: {
                date: {
                  maxYearsInPast: 100,
                  maxYearsInFuture: 5,
                },
              },
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_education_level",
                    value: "Graduated from college/university",
                  },
                  {
                    name: "parent_education_level",
                    value: "Graduate School",
                  },
                  {
                    name: "parent_education_level",
                    value: "Doctoral Program",
                  },
                  {
                    name: "parent_alumni",
                    value: "Yes",
                  },
                ],
                logic: "0&&(1||2||3)&&4",
              },
            },
          ],
        },
        {
          title: "Siblings (Optional):",
          description:
            "Please list your siblings. Please note that the application allows you to enter up to five (5) siblings. If you have more than 5 siblings, please first list the siblings who are legacies (siblings who have graduated or attended Creighton), and email the information about the remaining siblings to admissions@creighton.edu",
          nestedSectionsMax: 5,
          nestedSectionTitle: "Sibling {{x}}",
          nestedSectionItemName: "Sibling",
          controlSectionName: "siblings",
          fields: [
            {
              name: "sibling_existence",
              label: "Do you have siblings?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              prefill: [
                {
                  value: "Yes",
                },
              ],
              hide: true,
            },
            {
              name: "sibling_first_name",
              type: "text",
              label: "First Name",
            },
            {
              name: "sibling_last_name",
              type: "text",
              label: "Last Name",
            },
            {
              name: "sibling_relationship",
              type: "radio",
              label: "Relationship",
              options: optionize([
                "Brother",
                "Sister",
                "Step-Brother",
                "Step-Sister",
              ]),
            },
            {
              name: "sibling_current",
              label:
                "Is this sibling a current student or graduate of Creighton University?",
              type: "radio",
              options: optionize([
                "Yes, this sibling is a graduate of Creighton",
                "Yes, this sibling is a current student at Creighton",
                "No, this sibling never attended Creighton",
              ]),
            },
            {
              name: "sibling_grad_year",
              label: "Graduation Year",
              type: "year",
              validations: {
                date: {
                  maxYearsInPast: 100,
                  maxYearsInFuture: 5,
                },
              },
              show: {
                conditions: [
                  {
                    name: "sibling_current",
                    value: "Yes, this sibling is a graduate of Creighton",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Alumni (Optional):",
          conditionalSection: true,
          description:
            "Has anyone else in your family attended Creighton University? You can share up to 4 family members who attended or are currently enrolled.",
          fields: [
            {
              name: "alumni_shared",
              label:
                "Can you share someone who attended or is currently enrolled?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              prefill: [
                {
                  value: "No",
                },
              ],
              isRequired: true,
            },
          ],
        },
        {
          nestedSectionsMax: 4,
          nestedSectionTitle: "Alumni {{x}}",
          nestedSectionItemName: "Alumni",
          controlSectionName: "alumnis",
          description:
            "Has anyone else in your family attended Creighton University? You can share up to 4 family members who attended or are currently enrolled.",
          isOptional: true,
          hide: {
            conditions: [
              {
                name: "alumni_shared",
                value: "No",
              },
            ],
          },
          fields: [
            {
              name: "alumni_relationship",
              label: "Relationship",
              type: "select",
              options: optionize([
                "Step-Mother",
                "Step-Father",
                "Step-Brother",
                "Step-Sister",
                "Grandparent",
                "Uncle",
                "Aunt",
                "Cousin",
                "Other",
              ]),
            },
            {
              name: "alumni_relationship_other",
              type: "text",
              label: "Relationship",
              show: {
                conditions: [
                  {
                    name: "alumni_relationship",
                    value: "Other",
                  },
                ],
              },
            },
            {
              name: "alumni_first_name",
              label: "First Name",
              type: "text",
              group: 1,
              order: 1,
            },
            {
              name: "alumni_last_name",
              label: "Last Name",
              type: "text",
              group: 1,
              order: 2,
            },
            {
              name: "alumni_grad_year",
              label: "Graduation Year",
              type: "year",
              validations: {
                date: {
                  maxYearsInPast: 100,
                  maxYearsInFuture: 5,
                },
              },
            },
          ],
        },
      ],
    },
    {
      id: "your-experience",
      slug: "your-experience",
      title: "Your Experience",
      navTitle: "Your Experience",
      description: "We'd like to know about your accomplishments",
      sections: [
        {
          title: "Most Recent Education",
          description: "Your Current or Most Recent High School",
          fields: [
            {
              name: "base_institution_ceeb",
              label: "Your school",
              helperText: "Start typing to search for your school",
              type: "highSchoolSearch",
              isRequired: true,
            },
            {
              name: "base_institution_name",
              type: "text",
              label: "School Name",
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
            },
            {
              name: "base_institution_country",
              type: "countrySearch",
              label: "School Country",
              group: 1,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              // prefill: [
              //   {
              //     value: "United States",
              //     conditionSet: {
              //       conditions: [
              //         {
              //           name: "citizenship",
              //           value: "U.S. Dual Citizen",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Permanent Resident",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Citizen or U.S. National",
              //         },
              //       ],
              //       logicalOr: true,
              //     },
              //   },
              // ],
            },
            {
              name: "base_institution_state",
              type: "stateSearch",
              label: "School State",
              group: 1,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    value: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    value: "United States",
                  },
                ],
              },
            },
            {
              name: "base_institution_state_province",
              label: "School State/Province",
              type: "text",
              group: 1,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "base_institution_city",
              type: "text",
              label: "School City",
              group: 2,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
            },
            {
              name: "base_institution_from_date",
              label: "School Start Date",
              type: "month",
              group: 3,
              order: 1,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  isInPast: true,
                },
              },
              isRequired: true,
            },
            {
              name: "base_institution_to_date",
              label: "School End Date",
              type: "month",
              group: 3,
              order: 2,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  maxYearsInFuture: 2,
                },
              },
            },
          ],
        },
        {
          title: "Your Education History (Optional)",
          conditionalSection: true,
          description:
            "If you've attended any other high schools/secondary schools or colleges/universities, please enter them below, including any college courses you completed while in high school.",
          fields: [
            {
              name: "institution_attended",
              label:
                "Have you attended other high schools/secondary schools or colleges/universities?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              prefill: [
                {
                  value: "No",
                },
              ],
              isRequired: true,
            },
          ],
        },
        {
          nestedSectionsMax: 5,
          nestedSectionTitle: "Additional Education History {{x}}",
          controlSectionName: "institutions",
          title: "Your Education History",
          hide: {
            conditions: [
              {
                name: "institution_attended",
                value: "No",
              },
            ],
          },
          fields: [
            {
              name: "institution_school_type",
              label: "School Type",
              type: "radio",
              options: optionize(["High School", "College"]),
              prefill: [
                {
                  value: "High School",
                },
              ],
              isRequired: true,
            },
            {
              name: "institution_ceeb_high_school",
              label: "Start typing to search for your school",
              type: "highSchoolSearch",
              isRequired: true,
              show: {
                conditions: [
                  {
                    name: "institution_school_type",
                    value: "High School",
                  },
                ],
              },
            },
            {
              name: "institution_ceeb_college",
              label: "Start typing to search for your college",
              type: "collegeSearch",
              isRequired: true,
              show: {
                conditions: [
                  {
                    name: "institution_school_type",
                    value: "College",
                  },
                ],
              },
            },
            {
              name: "institution_ceeb_name",
              type: "text",
              label: "Institution Name",
              show: {
                conditions: [
                  {
                    name: "institution_ceeb_high_school",
                    value: "I don't see my school",
                  },
                  {
                    name: "institution_ceeb_high_college",
                    value: "I don't see my school",
                  },
                ],
                logicalOr: true,
              },
              isRequired: true,
            },
            {
              name: "institution_from_date",
              label: "Institution Start Date",
              type: "month",
              group: 3,
              order: 1,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  isInPast: true,
                },
              },
              isRequired: true,
            },
            {
              name: "institution_to_date",
              label: "Institution End Date",
              type: "month",
              group: 3,
              order: 2,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  maxYearsInFuture: 2,
                },
              },
            },
          ],
        },
        {
          title: "High School GPA",
          description: "",
          fields: [
            {
              name: "hs_gpa_cumulative",
              label: "HS GPA Cumulative",
              type: "number",
              isRequired: true,
              validations: {
                value: {
                  max: 100,
                },
              },
              errorText:
                "GPA must be less than (or equal to) maximum GPA possible",
            },
            {
              name: "hs_gpa_weighted_or_unweighted",
              label: "HS GPA Weighted or Unweighted",
              helperText:
                "Indicate which GPA rating scale your school uses. The weighted scale considers course difficulty while unweighted does not.",
              type: "radio",
              options: optionize(["Weighted", "Unweighted"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "High School Counselor",
          description:
            "To help make sure your transcripts and recommendations are submitted, please provide the name and email address for your high school guidance counselor.  You’ll also want to make sure your school counselor knows you’ve applied to Creighton University.",
          fields: [
            {
              name: "counselor_first_name",
              label: "First Name",
              type: "text",
              isRequired: true,
              group: 1,
              order: 1,
            },
            {
              name: "counselor_last_name",
              label: "Last Name",
              type: "text",
              isRequired: true,
              group: 1,
              order: 2,
            },
            {
              name: "counselor_email_address",
              label: "Email Address",
              type: "email",
              isRequired: true,
            },
          ],
        },
        {
          title: "Extracurricular Activities (Optional):",
          conditionalSection: true,
          description:
            "Share the activities you have participated in during high school/secondary school.",
          fields: [
            {
              name: "extracurricular_activity_taken",
              label: "Did you have any extracurricular activites?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              prefill: [
                {
                  value: "No",
                },
              ],
              isRequired: true,
            },
          ],
        },
        {
          title: "Extracurricular Activity",
          nestedSectionTitle: "Extracurricular Activity {{x}}",
          nestedSectionItemName: "Extracurricular",
          nestedSectionsMax: 6,
          controlSectionName: "extracurriculars",
          hide: {
            conditions: [
              {
                name: "extracurricular_activity_taken",
                value: "No",
              },
            ],
          },
          fields: [
            {
              name: "extracurricular_activity_type",
              label: "Activity type",
              type: "activitySearch",
            },
            {
              name: "extracurricular_activity_other",
              label: "Other Activities",
              type: "text",
              show: {
                conditions: [
                  {
                    name: "extracurricular_activity_type",
                    value: "Not Found",
                  },
                ],
              },
            },
            {
              name: "extracurricular_activity_name",
              label: "Name of Activity",
              type: "text",
            },
            {
              name: "extracurricular_activity_position",
              label: "Position/Leadership description",
              type: "textarea",
            },
            {
              name: "extracurricular_activity_grade_levels",
              label: "During which years did you participate?",
              type: "checkbox",
              options: optionize(["9", "10", "11", "12"]),
            },
          ],
        },
        {
          title: "Test Scores (Optional):",
          description:
            "Submitting test scores to Creighton University is optional. Learn more about Creighton's test score policy <a target='_blank' href='https://www.creighton.edu/admission-aid/undergraduate/how-to-apply/test-optional'> here</a>.",
          fields: [
            {
              name: "test_scores_considered",
              label:
                "Would you like your test scores to be included as part of your application?",
              type: "radio",
              options: optionize([
                ["I would like my ACT/SAT scores to be reviewed.", "Yes"],
                [
                  "Please do not include ACT/SAT as part of my application.",
                  "No",
                ],
              ]),
              isRequired: true,
            },
          ],
        },
        {
          title: "SAT",
          show: conditionals.showStandardizedTestFields,
          fields: [
            {
              name: "sat_considered",
              label: "Have you taken or will you take the SAT?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              subfields: {
                title: "SAT Scores",
                fields: [
                  {
                    name: "sat_date_taken",
                    label: "SAT Date Taken",
                    type: "month",
                    show: conditionals.showSatFields,
                    isRequired: true,
                    validations: {
                      date: {
                        maxYearsInPast: 5,
                        isInPast: true,
                      },
                    },
                  },
                  {
                    name: "sat_reading_writing_score",
                    label: "Evidence-Based Reading and Writing Score",
                    type: "integer",
                    show: conditionals.showSatFields,
                    isRequired: true,
                    validations: {
                      value: {
                        min: 200,
                        max: 800,
                        divisor: 10,
                      },
                    },
                  },
                  {
                    name: "sat_math_score",
                    label: "Math Score",
                    type: "integer",
                    show: conditionals.showSatFields,
                    isRequired: true,
                    validations: {
                      value: {
                        min: 200,
                        max: 800,
                        divisor: 10,
                      },
                    },
                  },
                  {
                    name: "sat_essay_analysis_score",
                    label: "Essay Analysis Score",
                    type: "integer",
                    show: conditionals.showSatFields,
                  },
                  {
                    name: "sat_essay_reading_score",
                    label: "Essay Reading Score",
                    type: "integer",
                    show: conditionals.showSatFields,
                  },
                  {
                    name: "sat_essay_writing_score",
                    label: "Essay Writing Score",
                    type: "integer",
                    show: conditionals.showSatFields,
                  },
                  {
                    name: "sat_future_tests",
                    label: "Future Test Dates",
                    type: "radio",
                    options: optionize(["Yes", "No"]),
                    show: conditionals.showSatFields,
                  },
                  {
                    name: "sat_future_testing_date",
                    label: "Future Testing Date",
                    type: "month",
                    group: 2,
                    order: 1,
                    show: conditionals.showSatFutureFields,
                    isRequired: true,
                    validations: {
                      date: {
                        maxMonthsInPast: 6,
                        maxYearsInFuture: 1,
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          title: "ACT",
          show: conditionals.showStandardizedTestFields,
          fields: [
            {
              name: "act_considered",
              label: "Have you taken or will you take the ACT?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              subfields: {
                title: "ACT Scores",
                fields: [
                  {
                    name: "act_date_taken",
                    label: "ACT Date Taken",
                    type: "month",
                    show: conditionals.showActFields,
                    isRequired: true,
                    validations: {
                      date: {
                        maxYearsInPast: 5,
                        isInPast: true,
                      },
                    },
                  },
                  {
                    name: "act_highest_composite_score",
                    label: "ACT Highest Composite Score",
                    type: "integer",
                    show: conditionals.showActFields,
                    isRequired: true,
                    validations: {
                      value: {
                        min: 0,
                        max: 36,
                      },
                    },
                  },
                  {
                    name: "act_english_score",
                    label: "English Score",
                    type: "integer",
                    show: conditionals.showActFields,
                    isRequired: true,
                    validations: {
                      value: {
                        min: 0,
                        max: 36,
                      },
                    },
                  },
                  {
                    name: "act_math_score",
                    label: "Math Score",
                    type: "integer",
                    show: conditionals.showActFields,
                    isRequired: true,
                    validations: {
                      value: {
                        min: 0,
                        max: 36,
                      },
                    },
                  },
                  {
                    name: "act_reading_score",
                    label: "Reading Score",
                    type: "integer",
                    show: conditionals.showActFields,
                    isRequired: true,
                    validations: {
                      value: {
                        min: 0,
                        max: 36,
                      },
                    },
                  },
                  {
                    name: "act_science_score",
                    label: "Science Score",
                    type: "integer",
                    show: conditionals.showActFields,
                    isRequired: true,
                    validations: {
                      value: {
                        min: 0,
                        max: 36,
                      },
                    },
                  },
                  {
                    name: "act_future_tests",
                    label: "Future Test Dates",
                    type: "radio",
                    options: optionize(["Yes", "No"]),
                    show: conditionals.showActFields,
                  },
                  {
                    name: "act_future_testing_date",
                    label: "Future Testing Date",
                    type: "month",
                    show: conditionals.showActFutureFields,
                    isRequired: true,
                    validations: {
                      date: {
                        maxMonthsInPast: 6,
                        maxYearsInFuture: 1,
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          title: "English Language Proficiency",
          fields: [
            {
              name: "english_language_proficiency_test",
              isRequired: true,
              label:
                "Have you taken or will you take an English Language Proficiency test?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
          ],
        },
        {
          title: "TOEFL",
          show: conditionals.showEnglishProficiencyFields,
          fields: [
            {
              name: "toefl_ielts_considered",
              label: "Do you want your TOEFL test scores considered?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              subfields: {
                title: "TOEFL Scores",
                fields: [
                  {
                    name: "toefl_ielts_date_taken",
                    label: "TOEFL Date Taken",
                    type: "month",
                    show: conditionals.showToeflFields,
                    isRequired: true,
                    validations: {
                      date: {
                        maxYearsInPast: 5,
                        isInPast: true,
                      },
                    },
                  },
                  {
                    name: "toefl_ielts_highest_score",
                    label: "TOEFL Highest Score",
                    type: "integer",
                    show: conditionals.showToeflFields,
                    isRequired: true,
                    validations: {
                      value: {
                        min: 0,
                        max: 120,
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Duolingo",
          show: conditionals.showEnglishProficiencyFields,
          fields: [
            {
              name: "duolingo_considered",
              label: "Do you want your Duolingo test scores considered?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              subfields: {
                title: "Duolingo Scores",
                fields: [
                  {
                    name: "duolingo_date_taken",
                    label: "Duolingo Date Taken",
                    type: "month",
                    show: conditionals.showDuolingoFields,
                    isRequired: true,
                    validations: {
                      date: {
                        maxYearsInPast: 5,
                        isInPast: true,
                      },
                    },
                  },
                  {
                    name: "duolingo_highest_score",
                    label: "Duolingo Highest Score",
                    type: "integer",
                    show: conditionals.showDuolingoFields,
                    isRequired: true,
                    validations: {
                      value: {
                        min: 10,
                        max: 160,
                      },
                    },
                  },
                  {
                    name: "duolingo_english_test_literacy_score",
                    label: "Duolingo English Test Literacy Score",
                    type: "integer",
                    show: conditionals.showDuolingoFields,
                    isRequired: true,
                    validations: {
                      value: {
                        min: 10,
                        max: 160,
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          title: "IELTS",
          show: conditionals.showEnglishProficiencyFields,
          fields: [
            {
              name: "ielts_considered",
              label: "Do you want your IELTS test scores considered?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              subfields: {
                title: "IELTS Scores",
                fields: [
                  {
                    name: "ielts_date_taken",
                    label: "IELTS Date Taken",
                    type: "month",
                    show: conditionals.showIeltsFields,
                    isRequired: true,
                    validations: {
                      date: {
                        maxYearsInPast: 5,
                        isInPast: true,
                      },
                    },
                  },
                  {
                    name: "ielts_highest_score",
                    label: "IELTS Highest Score",
                    type: "integer",
                    show: conditionals.showIeltsFields,
                    isRequired: true,
                  },
                  {
                    name: "ielts_listening_score",
                    label: "Listening Test Score",
                    type: "integer",
                    show: conditionals.showIeltsFields,
                    isRequired: true,
                  },
                  {
                    name: "ielts_reading_score",
                    label: "Reading Test Score",
                    type: "integer",
                    show: conditionals.showIeltsFields,
                    isRequired: true,
                  },
                  {
                    name: "ielts_speaking_score",
                    label: "Speaking Test Score",
                    type: "integer",
                    show: conditionals.showIeltsFields,
                    isRequired: true,
                  },
                  {
                    name: "ielts_writing_score",
                    label: "Writing Test Score",
                    type: "integer",
                    show: conditionals.showIeltsFields,
                    isRequired: true,
                  },
                ],
              },
            },
          ],
        },
        {
          title: "PTE",
          show: conditionals.showEnglishProficiencyFields,
          fields: [
            {
              name: "pte_considered",
              label: "Do you want PTE test scores considered?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              subfields: {
                title: "PTE Score",
                fields: [
                  {
                    name: "pte_date_taken",
                    label: "PTE Date Taken",
                    type: "month",
                    show: conditionals.showPteFields,
                    isRequired: true,
                    validations: {
                      date: {
                        maxYearsInPast: 5,
                        isInPast: true,
                      },
                    },
                  },
                  {
                    name: "pte_highest_score",
                    label: "PTE Highest Score",
                    type: "integer",
                    show: conditionals.showPteFields,
                    isRequired: true,
                    validations: {
                      value: {
                        min: 10,
                        max: 160,
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Legal Disclosure",
          description: "",
          fields: [
            {
              name: "misdemeanor_or_felony",
              label: "Have you ever been convicted of a misdemeanor or felony?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "misdemeanor_or_felony_explanation",
              label: "Please provide a brief explanation.",
              type: "textarea",
              show: {
                conditions: [
                  {
                    name: "misdemeanor_or_felony",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "misdemeanor_or_felony",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "disciplinary_violation",
              label:
                "Have you ever been found responsible for a disciplinary violation at any educational institution from the 9th grade forward, whether related to academic or behavioral misconduct?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "disciplinary_violation_explanation",
              label: "Please provide a brief explanation.",
              type: "textarea",
              show: {
                conditions: [
                  {
                    name: "disciplinary_violation",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "disciplinary_violation",
                    value: "Yes",
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title: "Program Options",
      id: "program-options",
      slug: "program-options",
      description: "Help us get to know more about your college plans.",
      navTitle: "Program Options",
      sections: [
        {
          title: "Application Details",
          description:
            "Tell us about the type of application you would like to submit to Creighton University.",
          fields: [
            {
              name: "transfer_app",
              label: "",
              type: "radio",
              options: optionize(["Yes", "No"]),
              prefill: [
                {
                  value: "No",
                },
              ],
              hide: true,
            },
            {
              name: "student_term",
              label: "Entering Term",
              type: "radio",
              options: optionize(["Spring 2025", "Fall 2025"]),
              prefill: [
                {
                  value: "Fall 2025",
                },
              ],
              isRequired: true,
            },
            {
              name: "intended_major",
              label: "Intended Major",
              helperText:
                "Let us know if you have a major in mind. If you don't yet, that's okay. You can select Undecided.",
              type: "select",
              options: optionize(CREIGHTON_MAJORS),
              isRequired: true,
            },
            {
              name: "pre_professional",
              label: "Pre-Professional",
              helperText:
                "(Please note: Preprofessional programs are not majors. An intended anticipated major must be chosen above.)",
              type: "select",
              options: optionize([
                "Pre-Dentistry",
                "Pre-Engineering",
                "Pre-Law",
                "Pre-Medicine",
                "Pre-Occupational Therapy",
                "Pre-Pharmacy",
                "Pre-Physical Therapy",
                "Pre-Physician Assistant",
                "Pre-Veterinary",
                "Pre-Nurse Practitioner",
              ]),
            },
            {
              name: "decision_to_apply",
              label: "Why have you decided to apply to Creighton University?",
              type: "textarea",
              isRequired: true,
            },
          ],
        },
        {
          title: "Personal Statement",
          description:
            "The essay demonstrates your ability to write clearly and concisely on a selected topic and helps you distinguish yourself in your own voice. What do you want the readers of your application to know about you apart from courses, grades, and test scores? Choose the option that best helps you answer that question and write an essay of no more than 650 words, using the prompt to inspire and structure your response. Remember: 650 words is your limit, not your goal. Use the full range if you need it, but don't feel obligated to do so. <br></br><ul> <li>Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story. </li> <li>The lessons we take from obstacles we encounter can be fundamental to later success. Recount a time when you faced a challenge, setback, or failure. How did it affect you, and what did you learn from the experience? </li> <li>Reflect on a time when you questioned or challenged a belief or idea. What prompted your thinking? What was the outcome? </li><li>Reflect on something that someone has done for you that has made you happy or thankful in a surprising way. How has this gratitude affected or motivated you? </li><li>Discuss an accomplishment, event, or realization that sparked a period of personal growth and a new understanding of yourself or others.</li><li> Describe a topic, idea, or concept you find so engaging that it makes you lose all track of time. Why does it captivate you? What or who do you turn to when you want to learn more? </li><li>Share an essay on any topic of your choice. It can be one you've already written, one that responds to a different prompt, or one of your own design.</li></ul>",
          fields: [
            {
              name: "essay_ready_to_submit",
              label: "Would you like to submit your personal statement now?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "essay_inline_upload",
              label:
                "Do you have your personal statement in a file you'd like to upload?",
              type: "radio",
              show: {
                conditions: [
                  {
                    name: "essay_ready_to_submit",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "essay_ready_to_submit",
                    value: "Yes",
                  },
                ],
              },
              options: optionize([
                ["Yes, I have my personal statement written in a file.", "Yes"],
                ["No, I'll write my personal statement here.", "No"],
              ]),
            },
            {
              name: "essay_file_upload",
              label: "Upload File",
              type: "file",
              show: {
                conditions: [
                  {
                    name: "essay_ready_to_submit",
                    value: "Yes",
                  },
                  {
                    name: "essay_inline_upload",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "essay_ready_to_submit",
                    value: "Yes",
                  },
                  {
                    name: "essay_inline_upload",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "essay_file_custom_name",
              label: "File Name",
              type: "text",
              show: {
                conditions: [
                  {
                    name: "essay_ready_to_submit",
                    value: "Yes",
                  },
                  {
                    name: "essay_inline_upload",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "essay",
              label:
                "Type your personal statement here, if you did not upload one above.",
              type: "textarea",
              show: {
                conditions: [
                  {
                    name: "essay_ready_to_submit",
                    value: "Yes",
                  },
                  {
                    name: "essay_inline_upload",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "essay_ready_to_submit",
                    value: "Yes",
                  },
                  {
                    name: "essay_inline_upload",
                    value: "No",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Financial Aid",
          description:
            "There are many sources of financial assistance to help with the cost of college. It's helpful to know if you plan to apply for financial aid so we can provide you with more information. Will you apply for financial aid using the Free Application for Federal Student Aid (FAFSA)? If so, our school code is 002542.",
          fields: [
            {
              name: "financial_aid",
              label: "Do you intend to apply for financial aid?",
              type: "radio",
              options: [
                {
                  value: "Yes",
                  label: "Yes, I intend to apply for financial aid.",
                },
                {
                  value: "No",
                  label: "No, I do not intend to apply for financial aid.",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}
