import { Dispatch, SetStateAction, useContext } from "react"

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DownloadIcon,
} from "@chakra-ui/icons"
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"

import { H4 } from "components/common"
import { ErrorIcon } from "components/ErrorIcon"

import { ClientContext } from "context/ClientContext"
import FormContext from "context/FormContext"

import { downloadFormJson } from "utilities/helpers"

interface DebugSubmissionModalProps {
  handleSubmitApplication(): void
  setIsSubmitting: Dispatch<SetStateAction<boolean>>
  setShowDebugModal: Dispatch<SetStateAction<boolean>>
}

export const DebugSubmissionModal = ({
  handleSubmitApplication,
  setIsSubmitting,
  setShowDebugModal,
}: DebugSubmissionModalProps) => {
  const { form } = useContext(FormContext)
  const { client } = useContext(ClientContext)

  const clearSubmission = () => {
    setIsSubmitting(false)
    setShowDebugModal(false)
  }

  const completeSubmission = () => {
    handleSubmitApplication()
    setShowDebugModal(false)
  }

  return (
    <Modal
      isOpen={true}
      onClose={clearSubmission}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        mt={48}
        borderStyle="solid"
        borderColor="transparent"
        borderLeftWidth={0}
        borderBottom={0}
      >
        <Alert
          variant="left-accent"
          borderColor="error"
          background="white"
          borderRadius="md"
          borderLeftWidth={8}
          boxShadow="lg"
          alignItems="flex-start"
          py={6}
        >
          <ErrorIcon color="error" mr={6} boxSize={8} />
          <Flex direction="column" gap={4}>
            <AlertTitle
              color="errorText"
              as={H4}
              display="flex"
              gap={2}
              justifyContent="space-between"
            >
              Submission Complete!
            </AlertTitle>
            <AlertDescription>
              <Text>
                A production user would be sent to a confirmation page.
              </Text>
              <Text>
                Click "Cancel Submission" to undo submission and return to the
                app or "Continue" to complete the process.
              </Text>
            </AlertDescription>
            <Flex justifyContent="space-between" alignItems="center">
              <Button
                variant="link"
                leftIcon={<DownloadIcon />}
                onClick={() => downloadFormJson(form.getValues(), client.theme)}
              >
                Download Form Data
              </Button>
              <ButtonGroup isAttached gap={2}>
                <Button
                  variant="outline"
                  size="sm"
                  leftIcon={<ChevronLeftIcon />}
                  mr={4}
                  onClick={clearSubmission}
                >
                  Cancel Submission
                </Button>
                <Button
                  variant="solid"
                  size="sm"
                  rightIcon={<ChevronRightIcon />}
                  onClick={completeSubmission}
                >
                  Continue
                </Button>
              </ButtonGroup>
            </Flex>
          </Flex>
        </Alert>
      </ModalContent>
    </Modal>
  )
}
