import { useContext } from "react"
import { AuthContext } from "context/AuthContext"
import { LoadingScreen } from "components/LoadingScreen"
import { ApplicationData } from "types"
import { motion } from "framer-motion"
import { useErrorModal } from "context/ErrorContext"

interface LogoutPageProps {
  applicationData: ApplicationData
}

export const LogoutPage = ({ applicationData }: LogoutPageProps) => {
  const { unsetTokens } = useContext(AuthContext)
  const { clearError } = useErrorModal()

  unsetTokens()
  clearError()

  return (
    <LoadingScreen
      data-testid="logout-page_loading-screen"
      height="full"
      as={motion.div}
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.5 }}
    />
  )
}
