import { useNavigate } from "react-router-dom"
import { ChevronRightIcon } from "@chakra-ui/icons"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Alert,
  Flex,
  AlertTitle,
  AlertDescription,
  Button,
} from "@chakra-ui/react"
import { H4 } from "components/common"
import { ErrorIcon } from "components/ErrorIcon"
import { AppRoutes } from "utilities/constants"
import { useErrorModal } from "context/ErrorContext"
import { useState } from "react"

export const ErrorModalAction = {
  LOGOUT: "logout",
  REFRESH: "refresh",
} as const

export type ErrorModalActionType =
  (typeof ErrorModalAction)[keyof typeof ErrorModalAction]

export type ErrorProps = {
  title?: string
  message?: string
  action?: ErrorModalActionType
  log?: string | Record<string, unknown>
}

const defaultTitle = "We're sorry! Something went wrong."
const defaultMessage = "We've encountered an unexpected error."
const defaultAction = "refresh"

const AppErrorModal = () => {
  const navigate = useNavigate()
  const { error, clearError } = useErrorModal()
  const [isLoading, setIsLoading] = useState(false)

  if (!error) return null

  if (error.log) {
    console.error(error.log)
  }

  const modalTitle = error.title || defaultTitle
  const modalMessage = error.message || defaultMessage
  const modalAction = error.action || defaultAction

  const isAuthError = modalAction === ErrorModalAction.LOGOUT

  const callToAction = isAuthError ? "Log back in" : "Refresh this page"

  const refreshPage = () => window.location.reload()

  const onAction = () => {
    if (isAuthError) {
      setIsLoading(true)
      // Push navigation to next event loop tick to ensure state updates are processed
      setTimeout(() => {
        navigate(AppRoutes.logout)
      }, 0)
    } else {
      clearError()
      refreshPage()
    }
  }

  return (
    <Modal
      isOpen={!!error}
      onClose={onAction}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        mt={48}
        borderStyle="solid"
        borderColor="transparent"
        borderLeftWidth={0}
        borderBottom={0}
      >
        <Alert
          variant="left-accent"
          borderColor="error"
          background="white"
          borderRadius="md"
          borderLeftWidth={8}
          boxShadow="lg"
          alignItems="flex-start"
          py={6}
        >
          <ErrorIcon color="error" mr={3} mt="1px" boxSize={6} />
          <Flex direction="column">
            <AlertTitle color="errorText" as={H4}>
              {modalTitle}
            </AlertTitle>
            <AlertDescription
              mt={2}
            >{`${modalMessage} Please ${callToAction.toLowerCase()} to pick up where you left off.`}</AlertDescription>
            <Flex mt="4">
              <Button
                variant="link"
                rightIcon={<ChevronRightIcon />}
                onClick={onAction}
                isLoading={isLoading}
                loadingText="Signing out..."
                isDisabled={isLoading}
              >
                {callToAction}
              </Button>
            </Flex>
          </Flex>
        </Alert>
      </ModalContent>
    </Modal>
  )
}

export default AppErrorModal
