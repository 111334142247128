import React, { useMemo } from "react"
import { Flex, Box, useStyleConfig } from "@chakra-ui/react"
import { FieldDataParser } from "components/FieldDataParser"
import { NestedSectionDataParser } from "components/NestedSectionDataParser"
import { FieldAttributes, SectionData } from "types"
import { SectionHeader } from "components/SectionHeader"
import { FormFieldsStack } from "components/FormFieldsStack"

export interface FormFieldsDisplayProps {
  sectionData: SectionData
  onFieldBlur?: React.FocusEventHandler<HTMLDivElement>
  nestedIndex?: number
  isRequiredErrors?: boolean
  isFinalCheck?: boolean
}

/**
 * Process an array of fields to identify visually grouped fields
 * Returns an object where keys are field indices and values are arrays of grouped fields
 */
const processGroupedFields = (fields: FieldAttributes[]) => {
  const grouped = {}

  fields.forEach((field, index) => {
    if (!field.group) return

    if (!grouped[field.group]) {
      grouped[field.group] = {
        elements: [],
        firstIndex: field.order === 1 ? index : Infinity,
      }
    }

    grouped[field.group].elements.push(field)

    if (field.order === 1) {
      grouped[field.group].firstIndex = index
    }
  })

  // Convert to index-based result
  const result = {}
  Object.values(grouped).forEach((group: any) => {
    result[group.firstIndex] = group.elements
  })

  return result
}

export const FormFieldsDisplay = ({
  sectionData,
  nestedIndex,
  onFieldBlur,
  isRequiredErrors,
  isFinalCheck,
}: FormFieldsDisplayProps) => {
  const {
    fields: sectionFields,
    nestedSectionsMax,
    controlSectionName,
  } = sectionData
  const nestedFieldsStyle = useStyleConfig("NestedSectionDataParser")

  /** Fields that are grouped on one line
   * - e.g. "First Name", "Middle Name", "Last Name" */
  const visuallyGroupedFields = useMemo(() => {
    return processGroupedFields(sectionFields)
  }, [sectionFields])

  /**
   * Returns an array of fields with `null` as placeholders for grouped fields.
   * Un-grouped fields are returned the same as they were passed in.
   * ```
   * // sectionFields goes
   * [
   *   {name: "counselor_first_name", group: 1, order: 1},
   *   {name: "counselor_last_name", group: 1, order: 2},
   *   {name: "counselor_email_address"}
   * ]
   *
   * // indexedFields comes out:
   * [ null, null, {name: "counselor_email_address"}]
   * ```
   */
  const indexedFields = useMemo(
    () => sectionFields.map(field => (field.group ? null : field)),
    [sectionFields],
  )

  if (nestedSectionsMax) {
    // Section has nested blocks that a user may add or remove
    // e.g. "Parent/Guardians", "Alumni", "High School Courses" sections
    return (
      <FormFieldsStack>
        <NestedSectionDataParser
          sectionData={sectionData}
          isFinalCheck={isFinalCheck}
        />
      </FormFieldsStack>
    )
  }

  const getFieldProps = (field: FieldAttributes) => ({
    nestedIndex,
    fieldData: field,
    onBlur: onFieldBlur,
    controlSectionName,
    isRequiredErrors,
    isFinalCheck,
    width: field.width,
  })

  const renderField = (field: FieldAttributes, index: number) => {
    if (!field) return null

    if (field.subfields) {
      // Process subfields for grouping
      const subfieldVisuallyGrouped = processGroupedFields(
        field.subfields.fields,
      )

      // Create indexed array with nulls for grouped fields
      const subfieldIndexed = field.subfields.fields.map(subfield =>
        subfield.group ? null : subfield,
      )

      return (
        <Box __css={nestedFieldsStyle} key={field.name + index}>
          <FormFieldsStack>
            {field.subfields.title && (
              <SectionHeader isNested title={field.subfields.title} />
            )}
            <FieldDataParser
              key={field.name + index}
              {...getFieldProps(field)}
            />

            {subfieldIndexed.map((subfield, subfieldIndex) => {
              if (subfieldVisuallyGrouped[subfieldIndex]) {
                return (
                  <Flex
                    className="combined-fields-wrapper"
                    key={`${field.name}-subfields-${subfieldIndex}`}
                    alignItems="baseline"
                    gap="5"
                    w="100%"
                    flexDirection={{ base: "column", tab: "row" }}
                  >
                    {subfieldVisuallyGrouped[subfieldIndex].map(
                      (groupField: FieldAttributes, fieldIndex: number) =>
                        renderField(groupField, fieldIndex),
                    )}
                  </Flex>
                )
              }

              return renderField(subfield, subfieldIndex)
            })}
          </FormFieldsStack>
        </Box>
      )
    }

    return (
      <FieldDataParser key={field.name + index} {...getFieldProps(field)} />
    )
  }

  return (
    <FormFieldsStack>
      {indexedFields.map((field, index) => {
        if (visuallyGroupedFields[index]) {
          // example: visuallyGroupedFields[0]
          // = [{name: "First Name", group: 1, order: 1}, {name: "Last Name", group: 1, order: 2}]
          return (
            <Flex
              className="combined-fields-wrapper"
              key={index}
              alignItems="baseline"
              gap="5"
              w="100%"
              flexDirection={{ base: "column", tab: "row" }}
            >
              {visuallyGroupedFields[index].map(
                (groupField: FieldAttributes, fieldIndex: number) =>
                  renderField(groupField, fieldIndex),
              )}
            </Flex>
          )
        }

        // Handle regular fields
        return renderField(field, index)
      })}
    </FormFieldsStack>
  )
}
