import { Box, Text } from "@chakra-ui/react"
import { H1 } from "components"
import { AuthFormWrapper } from "components/LayoutComponents"
import { SusiPageData } from "types"
import { AzureSignUpForm } from "pages/susi/forms/AzureSignUpForm"
import { AzureSignInForm } from "pages/susi/forms/AzureSignInForm"
import { AzureForgotPasswordForm } from "pages/susi/forms/AzureForgotPasswordForm"
import {
  useForgotPasswordScript,
  useSignInScript,
  useSignUpScript,
} from "pages/susi/hooks"
import { AppRoutes, AuthRoute } from "utilities/constants"
import { AuthToolbar } from "pages/susi/utils/AuthToolbar"

interface HollinsAuthPageProps {
  data: SusiPageData
  pageName: AuthRoute
}

export const HollinsAuthPage = ({ data, pageName }: HollinsAuthPageProps) => {
  const heading = data.heading && { __html: decodeURIComponent(data.heading) }

  const subheading = data.subheading && {
    __html: decodeURIComponent(data.subheading),
  }
  const htmlContent = data.htmlContent && {
    __html: decodeURIComponent(data.htmlContent),
  }
  const formHeading = data.formHeading && {
    __html: decodeURIComponent(data.formHeading),
  }

  const { authForgotPassword, authSignIn, authSignUp } = AppRoutes
  return (
    <>
      <AuthToolbar route={pageName} />
      <Box
        id="auth-page-heading"
        gridArea="heading"
        className="heading-wrapper desktop-left"
      >
        <Box>
          {heading && (
            <H1
              className={`${pageName}-heading`}
              dangerouslySetInnerHTML={heading}
            />
          )}
          {subheading && <Text dangerouslySetInnerHTML={subheading} />}
        </Box>
      </Box>
      <Box
        id="auth-form-container"
        gridArea="form"
        className={`${pageName} outer-form-wrapper desktop-right`}
      >
        <AuthFormWrapper id="auth-form">
          <Text id="auth-form-heading" dangerouslySetInnerHTML={formHeading} />
          {pageName === authForgotPassword && <HollinsForgotPasswordForm />}
          {pageName === authSignIn && <HollinsSignInForm />}
          {pageName === authSignUp && <HollinsSignUpForm />}
        </AuthFormWrapper>
      </Box>
      <Box
        id="auth-page-copy"
        gridArea="text-content"
        className="copy-wrapper desktop-left"
        dangerouslySetInnerHTML={htmlContent}
      />
    </>
  )
}

const HollinsForgotPasswordForm = () => {
  useForgotPasswordScript()
  return <AzureForgotPasswordForm showErrors={false} />
}

const HollinsSignInForm = () => {
  useSignInScript({
    customPasswordLabel: "Your Password",
  })
  return (
    <>
      {/* If user is returning from password reset route */}
      <Text
        id="passwordResetConfirmation"
        align="center"
        fontWeight="bold"
        mb={4}
        display="none"
      >
        Password reset successful. Please use new password below.
      </Text>
      <AzureSignInForm showErrors={false} />
      <Text id="auth-form-subtext">
        Need to start a brand new application?{/* eslint-disable-next-line */}
        <a href="#" className="userflow" role="button">
          Sign up now
        </a>
        .
      </Text>
    </>
  )
}
const HollinsSignUpForm = () => {
  useSignUpScript()
  return (
    <>
      <AzureSignUpForm showErrors={false} />
      <Text id="auth-form-subtext">
        Already started an application? {/* eslint-disable-next-line */}
        <a href="#" className="userflow" role="button">
          Sign In
        </a>
      </Text>
    </>
  )
}
