import { TextProps } from "@chakra-ui/react"
import { H2, H3 } from "components/common"
import { Status, StatusBadge } from "components/StatusBadge"
import { IconButton } from "components/IconButton"
import { CloseIcon } from "@chakra-ui/icons"

export interface SectionHeaderProps extends TextProps {
  title: string
  onClickRemove?: () => void
  isNested: boolean
  status?: Status
  canRemove?: boolean
}

export const SectionHeader = ({
  title,
  isNested,
  status,
  onClickRemove,
  canRemove,
  ...rest
}: SectionHeaderProps) => {
  const headerProps = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ...rest,
  }

  return !isNested ? (
    <H2 {...headerProps}>
      {title}
      {status && <StatusBadge status={status} />}
    </H2>
  ) : (
    <H3 {...headerProps}>
      {title}
      {canRemove && onClickRemove && (
        <IconButton
          ariaLabel="Remove section"
          onClick={onClickRemove}
          icon={<CloseIcon />}
        />
      )}
    </H3>
  )
}
