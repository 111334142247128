import { IS_PRODUCTION } from "utilities/constants"

/**
 * - Toggle `true` or `false` to display values when debugging different parts of the application.
 * - Adjust `ENABLE_LOGGING` to show or hide logs on prod.
 */
export const SHOW_LOGS = {
  ADB2C_TOKEN: false,
  API_ROUTE: false,
  APP_JSON: true,
  CLIENT_ID: false,
  ENVIRONMENT: false,
  INITIAL_USER_VALUES: true,
  TEALIUM: false,
  UPDATED_USER_FORM_VALUES: false,
  USER_EMAIL: false,
  USER_EXISTS: false,
  USER_TOKEN: false,
}

const ENABLE_LOGGING = !IS_PRODUCTION

export const log = (condition: boolean, ...args: any[]) => {
  if (ENABLE_LOGGING && condition) {
    console.log(...args)
  }
}

export const logGroup = (
  condition: boolean,
  groupLabel: string,
  ...args: any[]
) => {
  if (ENABLE_LOGGING && condition) {
    console.groupCollapsed(groupLabel)
    console.log(...args)
    console.groupEnd()
  }
}
