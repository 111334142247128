import { createContext, useContext } from "react"
import {
  FieldValues,
  useForm,
  UseFormReturn,
  FormProvider as ReactHookFormProvider,
} from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { User } from "types/user"
import { UserApi } from "api/user"
import {
  alphabetizeObject,
  getFinalData,
  isAuthorizationError,
  sanitizeFormValues,
  transformServerDataToFormData,
} from "utilities/helpers"
import { sendTealiumEvent } from "tealium"

import { log, SHOW_LOGS } from "utilities/logging"
import { ClientContext } from "context/ClientContext"
import { COLLEGE_NESTED_FIELDS } from "data"
import { useErrorModal } from "context/ErrorContext"

interface FormContextProps {
  validationSchema: any
  user: User
  token: string
  children?: React.ReactNode
}

export interface FormContextState {
  form: UseFormReturn<FieldValues, object>
  onSubmit: () => Promise<User | void>
  onSave: () => Promise<User | void>
}

export const initialFormContextState: FormContextState = {
  form: undefined,
  onSubmit: () => Promise.reject(() => {}),
  onSave: () => Promise.reject(() => {}),
}

const FormContext = createContext(initialFormContextState)
export default FormContext

export const FormContextProvider: React.FC<FormContextProps> = ({
  validationSchema,
  user,
  token,
  children,
}) => {
  const { client } = useContext(ClientContext)
  const { handleError } = useErrorModal()

  const arrayData = transformServerDataToFormData(
    { ...user.applicationData },
    COLLEGE_NESTED_FIELDS[client.theme],
  )

  const form = useForm({
    mode: "onChange", // This defines when the form validates
    resolver: yupResolver(validationSchema),
    defaultValues: arrayData,
  })

  const onSubmit = (): Promise<User | void> => {
    // Get data without hidden and empty fields in server format
    const data = getFinalData(form.getValues, client, form.setValue)

    const sanitizedData = sanitizeFormValues(data)

    return UserApi.finalize({
      token: token,
      user: {
        ...user,
        applicationData: {
          ...sanitizedData,
          queryParams:
            sanitizedData?.queryParams ||
            JSON.parse(localStorage.getItem("queryParams")) ||
            undefined,
        },
      },
    })
      .then(user => {
        if (user.submittedAt) {
          sendTealiumEvent("app_submit", {
            type: "link",
            email_address: user.email,
          })
        }
        return user
      })
      .catch(err => {
        if (isAuthorizationError(err)) {
          handleError({
            action: "logout",
            log: { message: "Authorization error onSubmit", err },
          })
        } else {
          console.error(err)
        }
      })
      .finally(() => {
        localStorage.getItem("queryParams") &&
          localStorage.removeItem("queryParams")
      })
  }

  const onSave = (): Promise<User | void> => {
    // Get data without hidden and empty fields in server format
    const data = getFinalData(form.getValues, client, form.setValue)

    const sanitizedData = sanitizeFormValues(data)

    return UserApi.replace({
      token: token,
      user: {
        ...user,
        applicationData: {
          ...sanitizedData,
          queryParams:
            sanitizedData?.queryParams ||
            JSON.parse(localStorage.getItem("queryParams")) ||
            undefined,
        },
      },
    })
      .then(user => {
        const userFormData = alphabetizeObject(user.applicationData)
        log(
          SHOW_LOGS.UPDATED_USER_FORM_VALUES,
          "%cUpdated form values:",
          "font-weight: bold",
          JSON.stringify(userFormData, null, 2),
        )
        return user
      })
      .catch(err => {
        if (isAuthorizationError(err)) {
          handleError({
            action: "logout",
            log: `Authorization error onSave: ${err}`,
          })
        } else {
          console.error(err)
        }
      })
  }

  const formContextState: FormContextState = {
    form,
    onSubmit,
    onSave,
  }

  return (
    <FormContext.Provider value={formContextState}>
      <ReactHookFormProvider {...form}>{children}</ReactHookFormProvider>
    </FormContext.Provider>
  )
}
