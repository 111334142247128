import { env } from "env"
import { ThemeOption } from "themes"

// https://docs.tealium.com/platforms/javascript/install/
export const tealiumInit = ({
  clientTheme,
  isAuthPage,
}: {
  clientTheme: ThemeOption
  isAuthPage?: boolean
}) => {
  const tealiumAccount = "rvaed"
  const tealiumEnv = isAuthPage ? "prod" : env.REACT_APP_TEALIUM_TARGET_ENV
  const tealiumProfile = tealiumEnv === "prod" ? clientTheme : "sandbox"
  const tealiumURL = `https://tags.tiqcdn.com/utag/${tealiumAccount}/${tealiumProfile}/${tealiumEnv}/utag.js`

  const existingScript = document.querySelector(`script[src="${tealiumURL}"]`)
  if (existingScript) {
    return { cleanup: () => {} }
  }

  return new Promise<void>(resolve => {
    // Create script element
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = tealiumURL
    script.async = true

    // Add window.utag initialization
    const initScript = document.createElement("script")
    initScript.type = "text/javascript"
    initScript.textContent =
      "window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};"

    // Insert initialization script first
    const firstScript = document.getElementsByTagName("script")[0]
    firstScript.parentNode.insertBefore(initScript, firstScript)

    // Then insert Tealium script
    firstScript.parentNode.insertBefore(script, initScript.nextSibling)

    // Wait for script to load
    script.onload = () => {
      resolve()
    }

    return {
      cleanup: () => {
        script.parentNode?.removeChild(script)
        initScript.parentNode?.removeChild(initScript)
      },
    }
  })
}
