import { useContext, useEffect, useState, useRef, useCallback } from "react"
import { useIdleTimer } from "react-idle-timer"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "context/AuthContext"
import { UserContext } from "context/UserContext"
import { sendTealiumEvent } from "tealium"
import { AuthApi } from "api/auth"
import { UserApi } from "api/user"
import { IDLE_TIMEOUT } from "utilities/constants"
import { getParamFromUrlHash, buildAuthRedirectUrl } from "utilities/helpers"
import { log, SHOW_LOGS } from "utilities/logging"
import { Authenticated } from "components/Authenticated"
import { ApplicationData } from "types"
import { ClientContext } from "context/ClientContext"
import { Unauthenticated } from "./Unauthenticated"
import { useErrorModal } from "context/ErrorContext"
import { SessionTimeoutModal } from "./SessionTimeoutModal"

export const AuthenticationWrapper = (localAppData: ApplicationData) => {
  const [errorLoadingToken, setErrorLoadingToken] = useState<boolean>(false)
  const { adb2cToken, token, setToken, setAdb2cToken, unsetTokens } =
    useContext(AuthContext)
  const { user, setUser } = useContext(UserContext)
  const { cid } = useContext(UserContext)
  const { client } = useContext(ClientContext)
  const navigate = useNavigate()
  const urlHash = window.location.hash

  const { handleError } = useErrorModal()

  const [loopCount, setLoopCount] = useState(1)
  const [loopSource, setLoopSource] = useState("UE1")
  const [showInactivityWarning, setShowInactivityWarning] = useState(false)
  const idleTimerRef = useRef<any>(null)

  const TIMEOUT_WARNING = "timeout-warning"

  /**
   * Prompt user after inactivity period
   */
  const onIdle = () => {
    setShowInactivityWarning(true)
  }

  /**
   * Reset the inactivity warning
   */
  const handleStayActive = useCallback(() => {
    setShowInactivityWarning(false)
    // Reset the idle timer
    if (idleTimerRef.current) {
      idleTimerRef.current.reset()
    }
  }, [])

  /**
   * Handle countdown after inactivity warning
   */
  const handleCountdownComplete = useCallback(() => {
    unsetTokens()
    setUser(undefined)
    navigate("/")
  }, [unsetTokens, setUser, navigate])

  useIdleTimer({
    onIdle,
    timeout: IDLE_TIMEOUT,
    ref: idleTimerRef,
  })

  useEffect(() => {
    const handleStayActiveEvent = () => handleStayActive()
    window.addEventListener(TIMEOUT_WARNING, handleStayActiveEvent)
    return () =>
      window.removeEventListener(TIMEOUT_WARNING, handleStayActiveEvent)
  }, [handleStayActive])

  /**
   * Clear the DB token on load to reauth from ADB2C token
   */
  useEffect(() => {
    const idTokenFromHash = getParamFromUrlHash(urlHash, "id_token")
    setLoopSource("UE2") // Login Loop 3
    setLoopCount(prevCount => prevCount + 1)

    // Hits on refresh loop or /logout route
    if (!idTokenFromHash) {
      return
    }

    // Hits on auth loop. Not refresh. adb2cToken is undefined
    if (idTokenFromHash !== adb2cToken) {
      log(SHOW_LOGS.ADB2C_TOKEN, "Clearing token, setting setAdb2cToken")
      setToken(undefined)
      setAdb2cToken(idTokenFromHash)
    }
  }, [urlHash, setToken, setAdb2cToken, adb2cToken])

  /**
   * On login, use adb2cToken to get/set db token
   * Hits on auth loop. Not refresh.
   */
  useEffect(() => {
    if (!errorLoadingToken && !token && adb2cToken) {
      setLoopSource("UE3")
      setLoopCount(prevCount => prevCount + 1)

      log(SHOW_LOGS.ADB2C_TOKEN, "Attempting to auth with adb2cToken: ", {
        adb2cToken,
      })

      AuthApi.authenticate({ idToken: adb2cToken, cid })
        .then(token => {
          setToken(token.idToken)
        })
        .catch(e => {
          /**
           * Check for 412 status (access denied)
           * If back-end says user needs to re-auth, like after passphrase reset, here we send them back to the Sign In page.  */
          if (e.response?.status === 412) {
            const signInUrl = buildAuthRedirectUrl({
              authServiceUrl: client.adb2cUrl,
              callbackUrl: client.redirectUrl,
              params: "passwordreset",
            })

            unsetTokens()

            window.location.href = signInUrl
              .toLowerCase()
              .replace("b2c_1a_signup", "b2c_1a_signin")
          }
          setErrorLoadingToken(true)
        })
    }
  }, [adb2cToken, errorLoadingToken, setToken, cid, token, client, unsetTokens])

  /**
   * Use `token` to load or create `user` data
   * Hits on page refresh loop (not auth)
   */
  useEffect(() => {
    if (token && !user) {
      setLoopSource("UE4")
      setLoopCount(prevCount => prevCount + 1)

      log(SHOW_LOGS.USER_TOKEN, "Attempting to .me:", token)

      UserApi.me({ token })
        .then(user => {
          setUser(user)
        })
        .catch(error => {
          handleError({
            action: "logout",
            log: { message: ".me has failed", error },
          })
        })
    }

    // eslint-disable-next-line
  }, [user, token, setUser, setAdb2cToken, setToken, navigate])

  useEffect(() => {
    if (user) {
      sendTealiumEvent("app_login", {
        type: "link",
        email_address: user.email,
      })
    }
  }, [user])

  /**
   * Must have `token` and `user` to pass this block
   */
  if (!token || !user) {
    if (!token && user) {
      // Expect to never see this message. Token loads before user.
      log(SHOW_LOGS.USER_TOKEN, `No token found. L${loopCount} ${loopSource}`)
    }
    if (token && !user) {
      // Expect Auth L4 UE3
      // Expect Refresh L3 UE4
      log(SHOW_LOGS.USER_EXISTS, `No user found. L${loopCount} ${loopSource}`)
    }
    if (!token && !user) {
      // Expect Auth L2 UE2
      // Expect Auth L4 UE3
      // Expect Auth L6 UE4
      // Expect Refresh L1 UE1
      log(
        SHOW_LOGS.USER_TOKEN,
        `No token or user found. L${loopCount} ${loopSource}`,
      )
    }

    return (
      <>
        <SessionTimeoutModal
          isOpen={showInactivityWarning}
          countdown={30}
          onStayActive={handleStayActive}
          onCountdownComplete={handleCountdownComplete}
        />
        <Unauthenticated {...localAppData} />
      </>
    )
  }

  log(SHOW_LOGS.USER_EMAIL, `Authenticated: ${user.email}`)
  return (
    <>
      <SessionTimeoutModal
        isOpen={showInactivityWarning}
        countdown={60}
        onStayActive={handleStayActive}
        onCountdownComplete={handleCountdownComplete}
      />
      <Authenticated {...localAppData} />
    </>
  )
}
