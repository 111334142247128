import { env } from "env"
import { useContext, useEffect, PropsWithChildren } from "react"
import { sendTealiumEvent } from "tealium"
import { AuthContext } from "context/AuthContext"
import { ClientContext } from "context/ClientContext"
import { UserContext } from "context/UserContext"
import { ClientApi } from "api/client"
import {
  IS_DEVELOPMENT,
  log,
  SHOW_LOGS,
  buildAuthRedirectUrl,
  getParamFromUrlHash,
  getSearchParams,
} from "utilities"

interface ChildrenProps {
  children: React.ReactNode
}

const ClientWrapper: React.FC<PropsWithChildren<ChildrenProps>> = ({
  children,
}) => {
  const { cid } = useContext(UserContext)
  const { adb2cToken, token } = useContext(AuthContext)
  const { client, setClient, isTealiumReady } = useContext(ClientContext)
  const urlHash = window.location.hash

  useEffect(() => {
    if (!client) {
      ClientApi.find({ cid }).then(res => {
        setClient(res)
      })
    }
    // eslint-disable-next-line
  }, [cid])

  useEffect(() => {
    // If theres no tokens, go auth through ADB2C
    const idTokenFromHash = getParamFromUrlHash(urlHash, "id_token")
    // When ADB2C authentication fails, it redirects back with an error parameter
    const errorFromHash = getParamFromUrlHash(urlHash, "error")
    const redirectionUrl = IS_DEVELOPMENT
      ? env.REACT_APP_ADB2C_REDIRECT_URL
      : client?.redirectUrl

    // If there's an authentication error in the URL, don't redirect
    // This prevents redirect loops and allows ADB2C error pages to display properly
    if (errorFromHash) {
      return
    }

    if (
      client &&
      !token &&
      !adb2cToken &&
      !idTokenFromHash &&
      redirectionUrl &&
      client.adb2cUrl
    ) {
      const queryParams = getSearchParams()

      const finalUrl = buildAuthRedirectUrl({
        authServiceUrl: client.adb2cUrl,
        callbackUrl: redirectionUrl,
        params: queryParams,
      })

      // Send event and redirect
      sendTealiumEvent("app_auth_redirect", {
        type: "link",
        customer_id: client.cid,
        // Only send uid if it exists
        ...(queryParams.uid ? { uid: queryParams.uid } : {}),
        onSuccess: () => {
          log(SHOW_LOGS.TEALIUM, `Redirecting to: ${finalUrl}`)
          window.location.replace(finalUrl)
        },
      })
    }
  }, [token, adb2cToken, urlHash, client, isTealiumReady])

  if (!client) {
    log(SHOW_LOGS.CLIENT_ID, "Client not found, showing loader")
    return <></>
  }

  log(SHOW_LOGS.CLIENT_ID, `Client loaded: ${client.cid}`)
  return <>{children}</>
}

export default ClientWrapper
