import { useEffect, useState } from "react"

interface UseCountdownProps {
  initialCount: number
  onComplete: () => void
  /**
   * Optional delay in milliseconds before firing onComplete callback.
   */
  timeoutDelay?: number
}

export const useCountdown = ({
  initialCount,
  onComplete,
  timeoutDelay = 0,
}: UseCountdownProps) => {
  const [count, setCount] = useState(initialCount)

  const reset = () => {
    setCount(initialCount)
  }

  useEffect(() => {
    if (count <= 0) {
      // Only use timeout if `timeoutDelay` is greater than 0
      if (timeoutDelay > 0) {
        const timeout = setTimeout(() => {
          onComplete()
        }, timeoutDelay)
        return () => clearTimeout(timeout)
      } else {
        // Execute immediately if no `timeoutDelay`
        onComplete()
        return
      }
    }

    const timer = setInterval(() => {
      setCount(prev => prev - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [count, onComplete, timeoutDelay])

  return { count, reset }
}
