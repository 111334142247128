import { WarningTwoIcon } from "@chakra-ui/icons"
import { Button } from "@chakra-ui/react"
import { useErrorModal } from "context/ErrorContext"
import { ErrorModalAction } from "components/AppErrorModal"

export const ThrowErrorButton = ({
  onClick,
  message = "Test error triggered by button click.",
  triggerAuthError = false,
  ...props
}) => {
  const { handleError } = useErrorModal()

  const handleClick = async () => {
    if (onClick) {
      await onClick(handleError)
    } else {
      handleError({
        message,
        action: triggerAuthError ? ErrorModalAction.LOGOUT : undefined,
        log: { message: "Test error triggered", triggerAuthError },
      })
    }
  }

  return (
    <Button
      leftIcon={<WarningTwoIcon />}
      onClick={handleClick}
      variant="solid"
      backgroundColor="red.500"
      _hover={{ backgroundColor: "red.600" }}
      {...props}
    >
      {triggerAuthError ? "Trigger Auth Error" : "Trigger Refresh Error"}
    </Button>
  )
}
