import { Option, OptionData, ComplexOptionItem } from "types"
import { when, whenAny, conditionals } from "data/utils/conditionals"

/**
 * Convert an array of strings or tuples to an array of objects with label and value keys
 * @example optionize(["Yes", "No"])
 * => [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]
 * @example optionize([["Yes, I agree.", "Yes"], ["No, I disagree.", "No"]])
 * => [{ label: "Yes, I agree.", value: "Yes" }, { label: "No, I disagree.", value: "No" }]
 */
export const optionize = (arr: OptionData): Option[] =>
  arr.map(item =>
    Array.isArray(item)
      ? { label: item[0], value: item[1] }
      : { label: item, value: item },
  )

/**
 * Filters options by a given label.
 * @param options - The array of options to filter.
 * @param label - The label to filter by.
 * @returns An array of options that match the given label.
 */
export function filterOptionsByLabel(
  options: OptionData,
  label: string,
): OptionData {
  return options.filter(([optionLabel]) => optionLabel === label)
}

/**
 * Gets the value of an option with a specific label.
 * @param options - The array of options to search.
 * @param label - The label of the option whose value we want to retrieve.
 * @returns The value of the first option that matches the given label, or undefined if no match is found.
 */
export function getOptionValueByLabel(
  options: OptionData,
  label: string,
): string | undefined {
  const option = options.find(([optionLabel]) => optionLabel === label)
  return option ? option[1] : undefined
}

/**
 * Filters out specified options from an array of options.
 * @param allOptions - The array of all options.
 * @param optionsToRemove - An array of options to be removed.
 * @returns A new array of options with the specified options removed.
 */
export function filterOutOptions(
  allOptions: OptionData,
  optionsToRemove: OptionData[],
): OptionData {
  const labelsToRemove = new Set(
    optionsToRemove.flatMap(options => options.map(option => option[0])),
  )
  return allOptions.filter(option => !labelsToRemove.has(option[0]))
}

/**
 * Creates an array of option data from an array of complex option items.
 *
 * @param items - An array of ComplexOptionItem objects to convert.
 * @returns An array of OptionData, where each item is a tuple containing the label and either the custom value or a combination of code and label.
 *
 * @example
 * const items: ComplexOptionItem[] = [
 *   { code: "CSSD", label: "Software Development" },
 *   { code: "CSWE", label: "Web Engineering", customValue: "CSWE - Web Dev" }
 * ];
 * const result = createOptionDataFromItems(items);
 * // Result: [["Software Development", "CSSD - Software Development"], ["Web Engineering", "CSWE - Web Dev"]]
 */
export function createOptionDataFromItems(
  items: ComplexOptionItem[],
): OptionData {
  return items.map(item => [
    item.label,
    item.customValue || `${item.code} - ${item.label}`,
  ])
}

export { when, whenAny, conditionals }
