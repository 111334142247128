import { useEffect } from "react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Alert,
  Flex,
  AlertTitle,
  AlertDescription,
  Button,
  Text,
  ModalCloseButton,
} from "@chakra-ui/react"

import { H4 } from "components/common"
import { ErrorIcon } from "components/ErrorIcon"

import { useCountdown } from "hooks/useCountdown"

interface SessionTimeoutModalProps {
  isOpen: boolean
  countdown: number
  onStayActive: () => void
  onCountdownComplete: () => void
}

export const SessionTimeoutModal = ({
  isOpen,
  countdown,
  onStayActive,
  onCountdownComplete,
}: SessionTimeoutModalProps) => {
  const { count, reset } = useCountdown({
    initialCount: countdown,
    onComplete: onCountdownComplete,
    timeoutDelay: 1000,
  })

  useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen, reset])

  const isTimeoutInitiated = count <= 0

  return (
    <Modal
      isOpen={isOpen}
      onClose={onStayActive}
      closeOnOverlayClick={true}
      closeOnEsc={!isTimeoutInitiated}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        mt={48}
        borderStyle="solid"
        borderColor="transparent"
        borderLeftWidth={0}
        borderBottom={0}
      >
        <Alert
          variant="left-accent"
          borderColor="error"
          background="white"
          borderRadius="md"
          borderLeftWidth={8}
          boxShadow="lg"
          alignItems="flex-start"
          py={6}
        >
          <ErrorIcon color="error" mr={3} mt="1px" boxSize={6} />
          <Flex direction="column">
            <ModalCloseButton
              top={3}
              right={3}
              isDisabled={isTimeoutInitiated}
            />
            <AlertTitle color="errorText" as={H4}>
              Are you still here?
            </AlertTitle>
            <AlertDescription mt={2}>
              Your session is about to expire due to inactivity. You will be
              automatically logged out in{" "}
              <Text as="span" fontWeight="bold">
                {count} seconds
              </Text>
              .
            </AlertDescription>
            <Flex mt="4">
              <Button
                rightIcon={<ChevronRightIcon />}
                onClick={onStayActive}
                isDisabled={isTimeoutInitiated}
                isLoading={isTimeoutInitiated}
                loadingText="Signing out..."
              >
                I'm Still Here
              </Button>
            </Flex>
          </Flex>
        </Alert>
      </ModalContent>
    </Modal>
  )
}
