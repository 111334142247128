import { OptionData } from "types"

export const VANGUARD_MAJORS: OptionData = [
  "Undeclared",
  "Accounting",
  "Biology",
  "Biology: Biomedical Science",
  "Biology: Ecology",
  "Biology: Neuroscience",
  "Biotechnology",
  "Business Administration",
  "Communication: Film/Visual Storytelling",
  "Communication: Public Relations",
  "Communication: Technology & Emerging Media",
  "Computer Science",
  "Criminal Justice",
  "Engineering Physics",
  "English",
  "History/Political Science",
  "Kinesiology: Health and Human Performance",
  "Kinesiology: Pre-Health Professions",
  "Liberal Studies (Education)",
  "Marketing",
  "Ministry Management",
  "Music",
  "Music Education",
  "Music: Worship Leadership",
  // "Pre-Nursing / Nursing",
  "Psychology",
  "Sociology",
  "Theatre Arts: Musical Theatre",
  "Theatre Arts: Stage & Screen Acting/Directing",
  "Theatre Arts: Technical/Design",
  "Theology: Biblical Studies",
  "Theology: General Christian Studies",
  "Theology: Intercultural Studies",
  "Theology: Pastoral Leadership Studies",
]
