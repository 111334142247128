import { Navigate, Route, Routes } from "react-router-dom"
import { ApplicationData, SusiPageData } from "types"
import { PageDataParser } from "./PageDataParser"
import { ScrollToTop } from "components/common"
import { AppRoutes, AuthRoutes, IS_PRODUCTION } from "utilities/constants"
import { FinalCheckPage } from "pages/FinalCheckPage"
import ConfirmationPage from "pages/ConfirmationPage"
import { LogoutPage } from "pages/LogoutPage"
import { UserContext } from "context/UserContext"
import { useContext } from "react"
import { DefaultAuthPage } from "pages/susi/DefaultAuthPage"
import { HollinsAuthPage } from "pages/susi/clients/hollins/HollinsAuthPage"
import { ClientContext } from "context/ClientContext"
import { DevToolsSidebar } from "devTools/DevToolsSidebar"

/**
 * A component for generating an application given the applications data in JSON format
 *
 * @param applicationData The JSON representing the application information
 * @returns The component representation of the application data
 */

export const ApplicationDataParser = (applicationData: ApplicationData) => {
  const { pages } = applicationData
  const { user } = useContext(UserContext)
  const { client } = useContext(ClientContext)

  return (
    <ScrollToTop>
      <Routes>
        <Route
          key={AppRoutes.logout}
          path={`/${AppRoutes.logout}`}
          element={<LogoutPage applicationData={applicationData} />}
        />

        {user?.submittedAt ? (
          // If a user has submitted their app, they can only access /confirmation and /logout
          <>
            <Route
              path={`/${AppRoutes.confirmation}`}
              element={<ConfirmationPage applicationData={applicationData} />}
            />
            <Route
              path="*"
              element={<Navigate to={`/${AppRoutes.confirmation}`} />}
            />
          </>
        ) : (
          // If a user has not submitted their app, they can access all pages except /confirmation
          <>
            {pages.map(pageData => (
              <Route
                key={pageData.slug}
                path={`/${pageData.slug}`}
                element={<PageDataParser pageData={pageData} />}
              />
            ))}
            <Route
              key={AppRoutes.finalCheck}
              path={`/${AppRoutes.finalCheck}`}
              element={<FinalCheckPage applicationData={applicationData} />}
            />
            <Route
              key={AppRoutes.confirmation}
              path={`/${AppRoutes.confirmation}`}
              // If a user with unfinished application hits /confirmation, redirect to /final-check
              element={<Navigate to={`/${AppRoutes.finalCheck}`} />}
            />

            {/* View auth pages for CSS/JS work to copy as HTML to Azure */}
            {!IS_PRODUCTION &&
              AuthRoutes.map(route => {
                const { signUp, signIn, forgotPassword } =
                  applicationData.susiPages
                const { authSignUp, authSignIn } = AppRoutes

                let pageData: SusiPageData
                switch (route) {
                  case authSignUp:
                    pageData = signUp
                    break
                  case authSignIn:
                    pageData = signIn
                    break
                  default:
                    pageData = forgotPassword
                }

                let AuthPage = (
                  <DefaultAuthPage
                    applicationData={applicationData}
                    pageName={route}
                  />
                )

                if (client.theme === "hollins") {
                  AuthPage = (
                    <HollinsAuthPage data={pageData} pageName={route} />
                  )
                }

                return (
                  <Route key={route} path={`/${route}`} element={AuthPage} />
                )
              })}

            {/* Catch-all route redirects any non-existent URL to Page 1 */}
            <Route path="*" element={<Navigate to={pages[0].slug} replace />} />
          </>
        )}
      </Routes>
      {!IS_PRODUCTION && <DevToolsSidebar />}
    </ScrollToTop>
  )
}
