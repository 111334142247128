import { uniq, isEmpty } from "lodash"
import {
  ApplicationData,
  FieldMetaData,
  FieldAttributes,
  SectionData,
} from "types"
import { IGNORE_PREFIX } from "utilities/constants"
import { determineIsRequired, determineShowField } from "./conditions"
import { toLowercaseString } from "./formatters"
import {
  COLLEGE_NESTED_FIELDS,
  COLLEGE_NESTED_FIELDS_TO_PLAIN_OBJECT,
} from "data"

export function getAllFields(appData: ApplicationData) {
  return appData.pages.flatMap(page =>
    page.sections.flatMap(section => section.fields),
  )
}

export const getAllFieldsOnCard = (section: SectionData): string[] => {
  let cardFields: string[] = []

  // Helper function to recursively collect field names including subfields
  const collectFieldNames = (fields: FieldAttributes[]): string[] => {
    let names: string[] = []

    fields.forEach(field => {
      names.push(field.name)

      // Recursively collect subfield names if they exist
      if (field.subfields?.fields?.length) {
        names = [...names, ...collectFieldNames(field.subfields.fields)]
      }
    })

    return names
  }

  if (isNestedSection(section)) {
    if (section.controlSectionName) {
      cardFields = [...cardFields, section.controlSectionName]
    } else {
      cardFields = [...cardFields, ...collectFieldNames(section.fields)]
    }
  } else {
    cardFields = [...cardFields, ...collectFieldNames(section.fields)]
  }

  return uniq(cardFields)
}

export const getRelevantSectionErrors = (
  sectionData: SectionData,
  errors: {
    [x: string]: any
  },
) => {
  const cardFields = getAllFieldsOnCard(sectionData)
  const realErrors = { ...errors }
  Object.keys(errors).forEach(error => {
    if (!cardFields.includes(error)) {
      delete realErrors[error]
    }
  })
  return realErrors
}

export function getAllSections(appData: ApplicationData): SectionData[] {
  return appData.pages.flatMap(page => page.sections)
}

export function getFieldMetaData(
  appData: ApplicationData,
  fieldName: string,
): FieldMetaData {
  let metaData: FieldMetaData = {
    page: undefined,
    section: undefined,
  }
  appData.pages.forEach((page, pageNumber) => {
    page.sections.forEach(section => {
      section.fields.forEach(field => {
        if (field.name === fieldName) {
          metaData = { page: page, section: section, pageNumber: pageNumber }
        }
      })
    })
  })
  return metaData
}

export function getSectionMetaData(
  appData: ApplicationData,
  section: SectionData,
): FieldMetaData {
  let metaData: FieldMetaData = {
    page: undefined,
    section: undefined,
  }

  appData.pages.forEach((page, pageNumber) => {
    page.sections.forEach(tempSection => {
      if (section.title === tempSection.title)
        metaData = { page: page, section: section, pageNumber: pageNumber }
    })
  })

  return metaData
}

export function getSectionErrors(
  errors: {
    [x: string]: any
  },
  section: SectionData,
): { [x: string]: any }[] {
  let fieldErrors: { [x: string]: any }[] = []
  section.fields.forEach(field => {
    if (!!errors[field.name]) {
      fieldErrors = [...fieldErrors, errors[field.name]]
    }
  })
  return fieldErrors
}

export const isFieldEmpty = (fieldValue: any) => {
  if (Array.isArray(fieldValue)) {
    return fieldValue.length === 0
  }
  return !fieldValue
}

export function getIsFieldComplexRequiredAndEmpty(
  field: FieldAttributes,
  formValues: { [x: string]: any },
  nestedIndex?: number,
  isFinalCheck?: boolean,
  isMultipleSection?: boolean,
): boolean {
  const fieldName =
    nestedIndex !== undefined && isMultipleSection
      ? field.name.replace("_", `_${nestedIndex + 1}_`)
      : field.name

  return (
    determineIsRequired(
      field,
      formValues,
      nestedIndex,
      isFinalCheck,
      isMultipleSection,
    ) && isFieldEmpty(formValues[fieldName])
  )
}

export const isFieldIncomplete = (
  field: FieldAttributes,
  formValues: {
    [x: string]: any
  },
  errors: {
    [x: string]: any
  },
  nestedIndex?: number,
  isMultipleSection?: boolean,
): boolean => {
  const fieldIsShown = determineShowField(
    field,
    formValues,
    nestedIndex,
    true,
    isMultipleSection,
  )

  if (fieldIsShown) {
    const isFieldRequiredAndEmpty = getIsFieldComplexRequiredAndEmpty(
      field,
      formValues,
      nestedIndex,
      true,
      isMultipleSection,
    )

    if (fieldHasError(field, errors, nestedIndex) || isFieldRequiredAndEmpty) {
      return true
    }
  }

  return false
}

export const fieldHasError = (
  field: FieldAttributes,
  errors: {
    [x: string]: any
  },
  nestedIndex?: number,
) => {
  if (nestedIndex !== undefined) {
    return Object.keys(errors).some(
      errorField =>
        errorField === field.name.replaceAll("{{x}}", `${nestedIndex}`),
    )
  }

  return Object.keys(errors).some(errorField => errorField === field.name)
}

export const getInvalidFields = (
  fields: FieldAttributes[],
  formValues: {
    [x: string]: any
  },
  errors: {
    [x: string]: any
  },
): string[] => {
  let invalidFields: string[] = []
  fields.forEach(field => {
    if (isFieldIncomplete(field, formValues, errors)) {
      invalidFields = [...invalidFields, field.name]
    }

    // Check subfields if they exist
    if (field.subfields?.fields) {
      // Check if parent field is visible (and thus subfields should be checked)
      const fieldIsShown = determineShowField(field, formValues)

      if (fieldIsShown) {
        const subfieldErrors = getInvalidFields(
          field.subfields.fields,
          formValues,
          errors,
        )
        invalidFields = [...invalidFields, ...subfieldErrors]
      }
    }
  })

  return invalidFields
}

export const getIgnoredNestedTitle = (title: string): string => {
  return `${IGNORE_PREFIX}${title}`
}

export const getNestedSectionCount = (
  sectionData: SectionData,
  applicationData: {
    [key: string]: any
  },
) => {
  let count = 0

  count = applicationData[sectionData.controlSectionName]?.length || 0

  return count
}

export const getNestedFieldName = (
  field: FieldAttributes,
  nestedIndex: number,
): string => {
  return field.name.replace("_", `_${nestedIndex + 1}_`)
}

/**
 * The criteria for a nested section to have its fields addressed:
 *
 * -The nested section has some field answered
 * OR -The minimum number of nested sections hasn't been reached
 *
 * If the criteria is met, then validate the fields within the section
 * as normal.
 */
export const getInvalidNestedFields = (
  fields: FieldAttributes[],
  nestedSectionCount: number,
  minNestedCount: number,
  formValues: {
    [x: string]: any
  },
  errors: {
    [x: string]: any
  },
  controlSectionName?: string,
): string[] => {
  let invalidFields: string[] = []

  // For each nested section that exists, find incomplete fields

  for (let i = 0; i < (nestedSectionCount || minNestedCount); i++) {
    const hasAnyFormValueAnswered = fields.some(field => {
      const fieldName = controlSectionName
        ? getNestedFieldName(field, i)
        : field.name

      return formValues[fieldName] !== undefined
    })

    const shouldValidateSection =
      hasAnyFormValueAnswered ||
      (minNestedCount !== undefined && minNestedCount > 0 && minNestedCount > i)

    if (!shouldValidateSection) {
      // If this nested section shouldn't be validated, don't add its errors to the list
      break
    }

    const relevantFields = fields
      .filter(field => {
        return isFieldIncomplete(
          field,
          formValues,
          errors,
          i,
          !!controlSectionName,
        )
      })
      .map(field => {
        return field.name
      })

    invalidFields = [...invalidFields, ...relevantFields]
  }

  return invalidFields
}

export const getNestedIndexFromTitle = (sectionData: SectionData) => {
  if (sectionData.nestedSectionsMax) {
    // Return the first instance of a number
    const numbers = sectionData.title.match("_[0-9]+")
    if (numbers && numbers.length > 0) {
      return parseInt(numbers[0].substring(1))
    }
  }

  return undefined
}

export const isNestedSection = (sectionData: SectionData) => {
  return sectionData.nestedSectionTitle || sectionData.nestedSectionsMax
}

interface BuildAuthRedirectUrlParams {
  authServiceUrl: string
  callbackUrl: string
  params?: Record<string, string> | string | string[]
}

/**
 * Builds an authentication redirect URL with query parameters
 * @param params.authServiceUrl The authentication service URL (e.g. ADB2C)
 * @param params.callbackUrl The URL to redirect to after authentication
 * @param params.params Optional query parameters as object, string, or array of strings
 * @returns The complete authentication URL with redirect_uri and any additional parameters
 */
export const buildAuthRedirectUrl = ({
  authServiceUrl,
  callbackUrl,
  params,
}: BuildAuthRedirectUrlParams): string => {
  const url = new URL(authServiceUrl)
  url.searchParams.set("redirect_uri", callbackUrl)

  // Add any additional query parameters
  if (params) {
    if (typeof params === "string") {
      url.searchParams.set(params, "")
    } else if (Array.isArray(params)) {
      params.forEach(param => url.searchParams.set(param, ""))
    } else {
      Object.entries(params).forEach(([key, value]) => {
        url.searchParams.set(key, value)
      })
    }
  }

  return url.toString()
}

/**
 * Get the search parameters from the URL
 * @returns {Record<string, string>} The search parameters as an object with key-value pairs
 * @example
 * const searchParams = getSearchParams() // http://localhost:3000/path?p1=val1&p2=val2
 * will return { p1: 'val1', p2: 'val2' }
 */
export const getSearchParams = () => {
  const params = new URLSearchParams(window.location.search)
  return Object.fromEntries(params.entries()) as Record<string, string>
}

export const getParamFromUrlHash = (
  hash: string,
  param: string,
): string | undefined => {
  const splitStr = hash.split(`${param}=`)
  if (splitStr.length > 0) {
    return splitStr[1]
  }
}

export const getHeaderFromHeaders = (headersString: string, header: string) => {
  const splitStr = headersString.split("\r\n")
  const headerString = splitStr.find(str => str.includes(header))
  if (headerString) {
    const headerSplitStr = headerString.split(": ")
    if (headerSplitStr.length > 0) {
      return headerSplitStr[1]
    }
  }
  return undefined
}

export const getApiHeaders = ({
  token,
  isFileUpload,
}: {
  token?: string
  isFileUpload?: boolean
}) => {
  if (token && isFileUpload) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  } else if (token) {
    return { headers: { Authorization: `Bearer ${token}` } }
  }
  return {}
}

export const isEmptyEquals = (x: any, y: any): boolean => {
  if (x === "" || x === null || x === undefined) {
    return y === "" || y === null || y === undefined
  }

  return x === y
}

export const lessThanOneMinuteAgo = date => {
  const ONE_MINUTE = 60 * 1000
  const oneMinuteAgo = Date.now() - ONE_MINUTE
  return date > oneMinuteAgo
}

export const sanitizeFormValues = (data: {
  [key: string]: any
}): { [key: string]: any } => {
  Object.keys(data).forEach(key => {
    if (data[key] === null || data[key] === undefined) {
      delete data[key]
    }
  })
  return data
}

// Allow or disallow a user to user specific characters in an input
export const filterKeyboardInput = (
  event: React.KeyboardEvent<HTMLInputElement>,
  filter: "allow" | "disallow",
  testChars: (string | number)[],
) => {
  const input = toLowercaseString(event.key)

  const inputIsAllowed =
    filter === "allow"
      ? // user input must match one of the test characters
        testChars.some(char => toLowercaseString(char) === input)
      : // filter === "disallow"
        // user input must not match ANY of the test characters
        testChars.every(char => toLowercaseString(char) !== input)

  // inputIsAllowed ? keypress works normally : keypress is disabled
  return inputIsAllowed ? null : event.preventDefault()
}

/**
 * @param count The number of items
 * @param unit The singular word for an item. e.g. "day" or "year"
 * @param unitPlural An optional custom plural version of the unit. e.g. "children" in place of "childs" for the unit "child"
 * @returns "day" if count === 1, or "5 days" if count equals 5.
 */
export const pluralize = (count: number, unit: string, unitPlural?: string) => {
  const pluralizedUnit = unitPlural || unit + "s"
  return count === 1 ? unit : `${count} ${pluralizedUnit}`
}

export const getValueOrNestedValue = (formValue: any) => {
  if (formValue && formValue.value !== undefined) {
    return formValue.value
  }

  return formValue
}

/**
 * Get a list of sections that are invalid or incomplete by the standards of the final check page
 */
export const getInvalidSections = ({
  applicationData,
  formValues,
  errors,
  currentValues,
}: {
  applicationData: ApplicationData
  formValues: {
    [x: string]: any
  }
  errors: {
    [x: string]: any
  }
  currentValues: {
    [x: string]: any
  }
}) => {
  let invalidSections = []

  getAllSections(applicationData).forEach(section => {
    const isSectionToValidate =
      isSectionStarted(section, formValues) || !section.isOptional

    if (isNestedSection(section) && isSectionToValidate) {
      // Generate individual field errors in nested section
      const fieldErrors = getInvalidNestedFields(
        section.fields,
        getNestedSectionCount(section, currentValues),
        section.nestedSectionsMin,
        formValues,
        errors,
        section.controlSectionName,
      )

      if (fieldErrors.length > 0) {
        invalidSections = [...invalidSections, section]
      }
    } else if (isSectionToValidate) {
      // Check for standard field errors or subfield errors
      const hasFieldErrors = section.fields.some(field => {
        // Check the field itself
        if (isFieldIncomplete(field, formValues, errors)) {
          return true
        }

        // Check subfields if applicable
        if (field.subfields?.fields && determineShowField(field, formValues)) {
          return field.subfields.fields.some(subfield =>
            isFieldIncomplete(subfield, formValues, errors),
          )
        }

        return false
      })

      if (hasFieldErrors) {
        invalidSections = [...invalidSections, section]
      }
    }
  })

  return invalidSections
}

export const isSectionStarted = (
  sectionData: SectionData,
  formValues: {
    [x: string]: any
  },
): boolean => {
  return sectionData.fields.some(field => {
    // Nested section field name will be modified, standard field name will stay the same
    const fieldName = field.name.replaceAll("{{x}}", "1")
    return formValues[fieldName] !== undefined
  })
}

/**
 * @param err The error containing the response
 * @returns Whether the error is a 401 or 403 network error. 401 is Unauthorized, 403 is Forbidden
 */
export const isAuthorizationError = (err: any) => {
  if (!err?.response?.status) return false
  const { status } = err.response
  return status === 401 || status === 403
}

//transform parents array to data fields as BE is expected data in this format
export const transformFormDataToServerData = (data, keysArray) => {
  const newData = data
  keysArray.forEach(key => {
    newData[key]?.map((el, ind) =>
      Object.keys(el).map(perentField => {
        const field = perentField.replace("_", `_${ind + 1}_`)
        data[field] = el[perentField]
        return field
      }),
    )

    delete newData[key]
  })
  return newData
}

//transform parents data to an array as FE form is expected data in this format
export const transformServerDataToFormData = (data, keysArray) => {
  const newData = data

  keysArray?.forEach(key => {
    newData[`${key}s`] = []

    Object.keys(newData).forEach(el => {
      if (el.includes(`${key}_`)) {
        //get index of an object we need to push to an array
        const match = el.match(/\d+/)
        let index: number

        if (match && match.length > 0) {
          index = parseInt(match[0])
        }

        //check whether we have the index to put only fields with numbers to the array (e.g. parent_1_address, not parent_existence)
        if (index) {
          const fieldName = el.replace(`_${index}_`, "_")

          newData[`${key}s`][index - 1]
            ? (newData[`${key}s`][index - 1][fieldName] = newData[el])
            : newData[`${key}s`].push({ [fieldName]: newData[el] })

          delete newData[el]
        }
      }
      return el
    })
  })

  return newData
}

export const transformDataToServerFormat = (data, arrayToCheck) => {
  //check if all the entities of arrayToCheck are marked as No
  const allFieldsAreRemoved = arrayToCheck.every(el => data[el.key] === "No")

  // if any of the entities of arrayToCheck are NOT marked as No - transform formValues
  if (!allFieldsAreRemoved) {
    const depArray = []

    // eslint-disable-next-line
    arrayToCheck.map(el => {
      if (data[el.key] === "No") {
        delete data[el.dependencyKey]
      } else {
        depArray.push(el.dependencyKey)
      }
    })
    return transformFormDataToServerData(data, depArray)
  }
}

const cleanupSection = ({ fields, values, setValue }) => {
  fields
    .filter(fieldData => values.hasOwnProperty(fieldData.name)) // Include only fields which already presented in data set
    .forEach(fieldData => {
      // hide:true uses as indicator that we never show this field, but need to have in data set,
      // that is why don't need to remove it from data set
      const isShown =
        fieldData.hide === true || determineShowField(fieldData, values)

      // Remove value from data set if it's hidden or empty
      if (!isShown || values[fieldData.name] === "") {
        !isShown && setValue(fieldData.name, "") // We need to reset field value manually in case we hide the field.
        delete values[fieldData.name]
      }
    })

  return values
}

// Get final data without hidden fields in server format
export const getFinalData = (getValues, client, setValue) => {
  const sections = getAllSections(client.data)

  let currentFields = []

  // Collect all sections in one array
  sections.forEach(({ fields }) => {
    currentFields = [...currentFields, ...fields]
  })

  const newData = getValues()

  // Check which multiple section presenten in the current data set
  const presentedMultipleSections = COLLEGE_NESTED_FIELDS[client.theme].filter(
    item => newData.hasOwnProperty(pluralizeString(item)),
  )

  // Go to each multiple section and clean from hidden and empty fields
  if (presentedMultipleSections.length > 0) {
    presentedMultipleSections.forEach(section => {
      if (newData[pluralizeString(section)].length > 0) {
        newData[pluralizeString(section)].map(element =>
          cleanupSection({
            fields: currentFields,
            values: element,
            setValue,
          }),
        )
        const firstItem = newData[pluralizeString(section)][0]
        // If multiple sections is presented but empty, just remove it
        if (firstItem && isEmpty(firstItem)) {
          delete newData[pluralizeString(section)]
        }
      } else {
        delete newData[pluralizeString(section)]
      }
    })
  }
  // Clean data set from hidden and empty fields
  const filteredData = cleanupSection({
    fields: currentFields,
    values: newData,
    setValue,
  })

  const arrayToCheck = COLLEGE_NESTED_FIELDS_TO_PLAIN_OBJECT[client.theme]

  // Transform data to server format
  return transformDataToServerFormat(filteredData, arrayToCheck)
}

export const pluralizeString = string => `${string}s`

export const setupQueryParamsToLocalStorage = () => {
  const queryParamsString = window.location.search

  if (!queryParamsString) {
    return
  }
  const queryParams = new URLSearchParams(queryParamsString)

  const queryParamsObject = {}
  for (const [key, value] of queryParams) {
    queryParamsObject[key] = value
  }

  queryParamsObject["queryString"] = queryParamsString

  const queryParamsName = "queryParams"
  const isValidQueryParams =
    localStorage.getItem(queryParamsName) &&
    !isEmpty(localStorage.getItem(queryParamsName))

  if (!isValidQueryParams) {
    localStorage.setItem(queryParamsName, JSON.stringify(queryParamsObject))
  }
}

export const alphabetizeObject = (
  obj: Record<string, any>,
): Record<string, any> => {
  return Object.keys(obj)
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }))
    .reduce(
      (result, key) => {
        result[key] = obj[key]
        return result
      },
      {} as Record<string, any>,
    )
}

/**
 * Prepares form data for download by removing email and formatting JSON
 */
export const prepareFormDataForDownload = (
  formData: Record<string, any>,
): string => {
  const dataToDownload = { ...formData }
  // Remove email address. If uploaded, it will be replaced with the current user's email.
  dataToDownload.email_address = ""
  return JSON.stringify(dataToDownload, null, 2)
}

/**
 * Downloads form data as a JSON file
 */
export const downloadFormJson = (
  formData: Record<string, any>,
  clientName: string,
  fileName?: string,
) => {
  const jsonContent = prepareFormDataForDownload(formData)
  const blob = new Blob([jsonContent], { type: "application/json" })
  const url = URL.createObjectURL(blob)

  const a = document.createElement("a")
  a.href = url
  a.download = fileName || `${clientName}-form-data.json`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}

/**
 * Processes uploaded JSON data by replacing email address
 */
export const processUploadedJson = (
  jsonData: Record<string, any>,
  emailAddress: string,
): Record<string, any> => {
  const processedData = { ...jsonData }
  processedData.email_address = emailAddress
  return processedData
}

/**
 * Uploads and processes a JSON file containing form data
 */
export const uploadFormJson = (
  event: React.ChangeEvent<HTMLInputElement>,
  emailAddress: string,
  onSuccess: (data: any) => void,
  onError?: (error: any) => void,
) => {
  const file = event.target.files?.[0]
  if (!file) return

  const reader = new FileReader()
  reader.onload = e => {
    try {
      const jsonData = JSON.parse(e.target?.result as string)
      const processedData = processUploadedJson(jsonData, emailAddress)
      onSuccess(processedData)
    } catch (error) {
      onError?.(error)
    }
  }
  reader.readAsText(file)
}
