import { createContext, useState, useEffect, useMemo } from "react"
import { Client } from "api/client"
import { AuthRoute, AuthRoutes } from "utilities/constants"
import { tealiumInit } from "tealium"
import { log, SHOW_LOGS } from "utilities/logging"

interface ClientContextState {
  client: Client
  setClient: (client: Client) => void
  isTealiumReady: boolean
}

const initialValue: ClientContextState = {
  client: undefined,
  setClient: () => {},
  isTealiumReady: false,
}

export const ClientContext = createContext(initialValue)

export const ClientProvider = ({ children }: React.PropsWithChildren) => {
  const [client, setClient] = useState<Client>()
  const [isTealiumReady, setIsTealiumReady] = useState(false)

  useEffect(() => {
    if (client) {
      const currentPath = window.location.pathname.slice(1)
      const isAuthPage = AuthRoutes.includes(currentPath as AuthRoute)

      const initializeTealium = async () => {
        try {
          await tealiumInit({
            clientTheme: client.theme,
            isAuthPage,
          })
          setIsTealiumReady(true)
          log(SHOW_LOGS.TEALIUM, "✅ Tealium initialized")
        } catch (error) {
          log(SHOW_LOGS.TEALIUM, "❌ Tealium failed to initialize:", error)
        }
      }

      initializeTealium()

      return () => {
        setIsTealiumReady(false)
      }
    }
  }, [client])

  const value = useMemo(
    () => ({ client, setClient, isTealiumReady }),
    [client, setClient, isTealiumReady],
  )

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  )
}
